import Cookies from 'js-cookie';
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import VuexPersistence from 'vuex-persist';
import axios from 'axios';

import { RootState } from 'qs_vuetify/src/types/states';

import AuthModule from 'qs_vuetify/src/store/auth/index';
import buildStoreModule from 'qs_vuetify/src/store/buildStoreModule';
import buildViewsStoreModule from 'qs_vuetify/src/store/views/buildViewsStoreModule';
import ComponentModule from 'qs_vuetify/src/store/components/index';
import GlobalViewModule from 'qs_vuetify/src/store/views/global';

import {
  Comment,
  ContactExchange,
  Contribution,
  DistrictElection,
  DistrictResult,
  InstanceRole,
  InstanceUser,
  MapSubdivision,
  Membership,
  Municipality,
  NationalEvent,
  NationalEventParticipation,
  PollingSector,
  PollingSubdivision,
  PostalCode,
} from 'qs_vuetify/src/types/models';

import { call_campaigns } from './callCampaigns';
import { campaigns } from './campaigns';
import { campaign_contacts } from './campaignContacts';
import { contacts } from './contacts';
import { contact_exchanges } from './contactExchanges';
import { contact_events } from './contactEvents';
import { contact_comments } from './contactComments';
import { districts } from './districts';
import { duplicates } from './duplicates';
import { events } from './events';
import { filters } from './filters';
import { form_definition_templates } from './formDefinitionTemplates';
import { instances } from './instances';
import { mail_campaigns } from './mailCampaigns';
import { objectives } from './objectives';
import { revisions } from './revisions';
import { sent_emails } from './sentEmails';
import { tags } from './tags';
import { transactions } from './transactions';
import { users } from './users';
import { volunteers } from './volunteers';

import { ContactsViewState } from '../types/states';

import views from './views/index';

declare const VERSION: string;

const {
  callCampaignsViewState,
  contactsViewState,
  electionResultsViewState,
  eventsViewState,
  globalViewState,
  mailCampaignsViewState,
  tagsViewState,
  volunteersViewState,
} = views;

Vue.use(Vuex);

const vuexLocal = new VuexPersistence<RootState>({
  key: 'qs_labase_v2',
  storage: window.localStorage,
  reducer(state) {
    const {
      commentsModal, // eslint-disable-line @typescript-eslint/no-unused-vars
      contactExchangeModal, // eslint-disable-line @typescript-eslint/no-unused-vars
      availableExportFields, // eslint-disable-line @typescript-eslint/no-unused-vars
      ...contactsView
    } = (state.contactsView as ContactsViewState);

    return {
      auth: {
        instanceId: state.auth?.instanceId,
      },
      global: {
        ...state.global,
        notifications: [],
        previousLocation: null,
      },
      contactsView,
      callCampaignsView: state.callCampaignsView,
      eventsView: state.eventsView,
      mailCampaignsView: state.mailCampaignsView,
      volunteersView: state.volunteersView,
    };
  },
});

const vuexCookie = new VuexPersistence<RootState>({
  key: 'qs_auth_v2',
  restoreState: (key) => Cookies.getJSON(key),
  saveState: async (key, state) => {
    Cookies.set(key, state, {
      expires: 3,
      secure: process.env.NODE_ENV !== 'development',
      domain: process.env.VUE_APP_COOKIE_DOMAIN,
      path: '/',
      sameSite: 'Lax',
    });
  },
  reducer(state) {
    return {
      auth: {
        lastInstanceId: state.auth?.lastInstanceId,
        previousInstanceId: state.auth?.previousInstanceId,
        refreshToken: state.auth?.refreshToken,
        token: state.auth?.token,
      },
    };
  },
});

const storeOptions: StoreOptions<RootState> = {
  state: {
    theme: 'labase',
    version: VERSION,
  },
  modules: {
    auth: AuthModule,
    callCampaignsView: callCampaignsViewState,
    call_campaigns,
    campaigns,
    campaign_contacts,
    comments: buildStoreModule<Comment>('comments'),
    components: ComponentModule,
    contact_events,
    contact_exchanges,
    contacts,
    contact_comments,
    contactsView: contactsViewState,
    contributions: buildStoreModule<Contribution>('contributions'),
    districts,
    district_elections: buildStoreModule<DistrictElection>('district_elections'),
    district_results: buildStoreModule<DistrictResult>('district_results'),
    duplicates,
    electionResultsView: electionResultsViewState,
    events,
    eventsView: eventsViewState,
    filters,
    form_definition_templates,
    global: GlobalViewModule,
    globalView: globalViewState,
    instances,
    instance_roles: buildStoreModule<InstanceRole>('instance_roles'),
    instance_users: buildStoreModule<InstanceUser>('instance_users'),
    mailCampaignsView: mailCampaignsViewState,
    mail_campaigns,
    map_subdivisions: buildStoreModule<MapSubdivision>('map_subdivisions'),
    mapSubdivisionsView: buildViewsStoreModule<MapSubdivision>({
      geometry: 1,
      type: 'BVO',
    }),
    memberships: buildStoreModule<Membership>('memberships'),
    municipalities: buildStoreModule<Municipality>('municipalities'),
    national_events: buildStoreModule<NationalEvent>('national_events', [], {
      path: 'national_events/events',
    }),
    national_event_participations: buildStoreModule<NationalEventParticipation>('national_event_participations', [], {
      path: 'national_events/participations',
    }),
    objectives,
    postal_codes: buildStoreModule<PostalCode>('postal_codes'),
    revisions,
    sent_emails,
    polling_sectors: buildStoreModule<PollingSector>('polling_sectors'),
    polling_sector_elections: buildStoreModule<PollingSector>('polling_sector_elections'),
    polling_subdivisions: buildStoreModule<PollingSubdivision>('polling_subdivisions'),
    pollingSubdivisionsView: buildViewsStoreModule<PollingSubdivision>({
      type: 'BVO',
      valid_ballots: '1:',
    }, {
      sortBy: ['number', 'type', 'name'],
      sortDesc: [false],
      page: 1,
      itemsPerPage: 1000,
    }),
    seen_contact_exchanges: buildStoreModule<ContactExchange>('contact_exchanges'),
    tags,
    tagsView: tagsViewState,
    transactions,
    volunteers,
    volunteersView: volunteersViewState,
    users,
  },
  plugins: [vuexLocal.plugin, vuexCookie.plugin],
};

const store = new Vuex.Store(storeOptions);

axios.interceptors.request.use((config) => {
  if (store.state.auth && store.state.auth.token) {
    // eslint-disable-next-line
    config.headers.common.Authorization = `Bearer ${store.state.auth.token}`;
  }
  return config;
});

export default store;
