var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "event-form", attrs: { fluid: "" } },
    [
      _c("item-navigation", {
        staticClass: "my-0",
        attrs: {
          callback: _vm.loadNextPage,
          item: _vm.item,
          items: _vm.data,
          loading: _vm.loading,
          "model-name": _vm.slug,
          total: _vm.total
        }
      }),
      _vm.itemReady
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [
                  _c("qs-card", {
                    staticStyle: { height: "100%" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [_vm._v("Configuration")]
                          },
                          proxy: true
                        },
                        {
                          key: "text",
                          fn: function() {
                            return [
                              _c("qs-form-builder", {
                                attrs: {
                                  form: _vm.form,
                                  order: _vm.formOrder,
                                  item: _vm.item,
                                  "model-name": _vm.slug
                                },
                                on: { input: _vm.syncItem },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "instance_id",
                                      fn: function() {
                                        return [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.instanceIdSelectItems,
                                              "hide-details": "",
                                              label: "Instance"
                                            },
                                            model: {
                                              value: _vm.item.instance_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.item,
                                                  "instance_id",
                                                  $$v
                                                )
                                              },
                                              expression: "item.instance_id"
                                            }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "duration",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c("qs-duration-field", {
                                            attrs: {
                                              label: "Durée de l'événement",
                                              name: "duration"
                                            },
                                            model: {
                                              value: item.duration,
                                              callback: function($$v) {
                                                _vm.$set(item, "duration", $$v)
                                              },
                                              expression: "item.duration"
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "should_send_email_reminder",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c("v-switch", {
                                            attrs: {
                                              label:
                                                "Envoyer un rappel par courriel",
                                              name: "should_send_email_reminder"
                                            },
                                            model: {
                                              value:
                                                item.should_send_email_reminder,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  item,
                                                  "should_send_email_reminder",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.should_send_email_reminder"
                                            }
                                          })
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  4284378778
                                )
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3542890166
                    )
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "qs-card",
                    { staticStyle: { height: "100%" } },
                    [
                      _vm.item
                        ? _c("v-text-field", {
                            attrs: {
                              filled: "",
                              "hide-details": "",
                              label: "Titre"
                            },
                            model: {
                              value: _vm.item.title,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "title", $$v)
                              },
                              expression: "item.title"
                            }
                          })
                        : _vm._e(),
                      _c("qs-html-editor", {
                        attrs: { options: { headings: false, hr: false } },
                        model: {
                          value: _vm.item.description,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "description", $$v)
                          },
                          expression: "item.description"
                        }
                      }),
                      _vm.user.superadmin && _vm.item.should_send_email_reminder
                        ? _c(
                            "div",
                            {
                              staticClass: "px-4 pt-2 pb-2",
                              staticStyle: { border: "2px solid #f4f4f4" }
                            },
                            [
                              _c(
                                "h3",
                                {
                                  staticClass: "text-overline font-weight-bold"
                                },
                                [_vm._v("Message du courriel de rappel")]
                              ),
                              _c("qs-html-editor", {
                                attrs: {
                                  options: { headings: false, hr: false }
                                },
                                model: {
                                  value: _vm.item.email_reminder_message,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.item,
                                      "email_reminder_message",
                                      $$v
                                    )
                                  },
                                  expression: "item.email_reminder_message"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [
                  _c(
                    "qs-card",
                    {
                      staticStyle: { height: "100%" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [_vm._v("Accessibilité")]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2421902883
                      )
                    },
                    [
                      _c("qs-form-builder", {
                        attrs: {
                          form: _vm.form,
                          order: [
                            "accessibility",
                            "accessibility_notes",
                            "location_name"
                          ],
                          item: _vm.item,
                          "model-name": _vm.slug
                        },
                        on: { input: _vm.syncItem }
                      }),
                      _c("location-field", {
                        attrs: { item: _vm.item, name: "location_field" },
                        on: { input: _vm.syncItem }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-center align-center",
                  staticStyle: { height: "40vh" }
                },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary" }
                  })
                ],
                1
              )
            ],
            1
          ),
      _vm.userHas("CONTACT_EVENTS_RETRIEVE")
        ? _c(
            "v-row",
            {
              staticClass: "event-form__stats mt-4",
              attrs: { "no-gutters": "" }
            },
            [
              _c(
                "v-col",
                [
                  _c(
                    "qs-card",
                    [
                      _c(
                        "qs-progress",
                        {
                          attrs: {
                            loading: !_vm.statsLoaded && !_vm.itemReady,
                            "model-name": "events",
                            values: [
                              {
                                color: "green",
                                key: "intentions.present",
                                value: _vm.stats
                                  ? _vm.stats.intentions.present
                                  : 0
                              },
                              {
                                color: "yellow",
                                key: "intentions.maybe",
                                value: _vm.stats
                                  ? _vm.stats.intentions.maybe
                                  : 0
                              },
                              {
                                color: "red",
                                key: "intentions.absent",
                                value: _vm.stats
                                  ? _vm.stats.intentions.absent
                                  : 0
                              },
                              {
                                value: _vm.stats ? _vm.stats.intentions.null : 0
                              }
                            ]
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "caption font-weight-bold text-uppercase"
                            },
                            [_vm._v(" Intentions de présence ")]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c(
                    "qs-card",
                    [
                      _c(
                        "qs-progress",
                        {
                          attrs: {
                            loading: !_vm.statsLoaded && !_vm.itemReady,
                            "model-name": "events",
                            values: [
                              {
                                color: "green",
                                key: "confirmations.confirmed",
                                value: _vm.stats ? _vm.stats.confirmed : 0
                              },
                              { value: _vm.stats ? _vm.stats.not_confirmed : 0 }
                            ]
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "caption font-weight-bold text-uppercase"
                            },
                            [_vm._v(" Confirmations d'inscription ")]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c(
                    "qs-card",
                    [
                      _c(
                        "qs-progress",
                        {
                          attrs: {
                            loading: !_vm.statsLoaded && !_vm.itemReady,
                            "model-name": "events",
                            values: _vm.presenceStats
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "caption font-weight-bold text-uppercase"
                            },
                            [_vm._v(" Présences ")]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.userHas("CONTACT_EVENTS_RETRIEVE")
        ? _c(
            "v-row",
            {
              staticClass: "event-form__contacts",
              attrs: { "no-gutters": "" }
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "3" } },
                [
                  _c("qs-card", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "append",
                          fn: function() {
                            return [
                              _c("qs-filters", {
                                attrs: {
                                  theme: "labase",
                                  "active-filters": _vm.contactsParams,
                                  "filters-definition": _vm.contactsFilters,
                                  "model-name": "contacts"
                                },
                                on: { input: _vm.updateContactsFilters }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      637041957
                    )
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "9" } },
                [
                  _c("qs-card", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(" Participant·es "),
                              _c("v-spacer"),
                              _c(
                                "v-menu",
                                {
                                  attrs: { bottom: "", "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "qs-button",
                                              _vm._b(
                                                {
                                                  staticClass: "mr-3",
                                                  attrs: {
                                                    small: "",
                                                    theme: "labase"
                                                  },
                                                  on: { click: on.click }
                                                },
                                                "qs-button",
                                                attrs,
                                                false
                                              ),
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "ml-2 mr-2" },
                                                  [_vm._v("Actions")]
                                                ),
                                                _c("v-icon", [
                                                  _vm._v("mdi-menu-down")
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1030596857
                                  )
                                },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: { link: "" },
                                          on: { click: _vm.exportContactsCsv }
                                        },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(" Exporter (CSV) ")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-file-export")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: { link: "" },
                                          on: { click: _vm.exportContactsXls }
                                        },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(" Exporter (XLS) ")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-file-export")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.userHas("CONTACT_EVENTS_CREATE")
                                ? _c(
                                    "qs-button",
                                    {
                                      attrs: { small: "", theme: "labase" },
                                      on: {
                                        click: function($event) {
                                          _vm.showAddSingleContactModal = true
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-plus")])],
                                    1
                                  )
                                : _vm._e()
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "append",
                          fn: function() {
                            return [
                              _c("qs-data-table", {
                                staticClass: "event-form__contacts__table",
                                attrs: {
                                  headers: _vm.contactsHeaders,
                                  items: _vm.contacts,
                                  loading:
                                    !_vm.routeDataLoaded || _vm.contactsLoading,
                                  options: _vm.contactsOptions,
                                  "set-options": _vm.setContactsOptions,
                                  total: _vm.contactsTotal || 0
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item.intention",
                                      fn: function(ref) {
                                        var contact = ref.item
                                        return [
                                          contact.contact_event_id
                                            ? _c(
                                                "qs-events-intention-indicator",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      contact.status ===
                                                        "draft" ||
                                                      !_vm.userHas(
                                                        "CONTACT_EVENTS_UPDATE"
                                                      ),
                                                    "contact-event-id":
                                                      contact.contact_event_id,
                                                    value: contact.intention,
                                                    "with-tooltip": ""
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.patchListContact(
                                                        contact,
                                                        "intention",
                                                        $event
                                                      )
                                                    }
                                                  }
                                                }
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.event_confirmations",
                                      fn: function(ref) {
                                        var contact = ref.item
                                        return [
                                          _c("qs-icon-button", {
                                            attrs: {
                                              color:
                                                (contact.event_confirmations &&
                                                  contact.event_confirmations
                                                    .length) > 0
                                                  ? "qs-green"
                                                  : "qs-red",
                                              disabled:
                                                !contact.intention ||
                                                !_vm.userHas(
                                                  "CONTACT_EVENTS_UPDATE"
                                                ),
                                              icon:
                                                contact.event_confirmations &&
                                                contact.event_confirmations
                                                  .length > 0
                                                  ? "mdi-check-circle-outline"
                                                  : "mdi-close-circle-outline",
                                              label: "Voir le détail",
                                              theme: "labase"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.showContactEventModal(
                                                  contact
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.presence",
                                      fn: function(ref) {
                                        var contact = ref.item
                                        return [
                                          _c("v-checkbox", {
                                            staticClass: "mt-0",
                                            attrs: {
                                              disabled:
                                                !_vm.item ||
                                                _vm.item.status === "draft" ||
                                                !_vm.eventStartsSoon ||
                                                !_vm.userHas(
                                                  "CONTACT_EVENTS_UPDATE"
                                                ),
                                              "input-value": contact.presence,
                                              indeterminate:
                                                contact.presence === null,
                                              "hide-details": ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.patchContactEvent({
                                                  id: contact.contact_event_id,
                                                  field: "presence",
                                                  value: $event
                                                })
                                              }
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.full_name",
                                      fn: function(ref) {
                                        var contact = ref.item
                                        return [
                                          _vm.userHas("CONTACTS_RETRIEVE") &&
                                          !contact.obfuscated
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "qs-dark-blue--text",
                                                  attrs: {
                                                    href: _vm.$router.resolve({
                                                      name: "ContactForm",
                                                      params: { id: contact.id }
                                                    }).href
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.goTo($event, {
                                                        name: "ContactForm",
                                                        params: {
                                                          id: contact.id
                                                        }
                                                      })
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        contact.full_name
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(contact.full_name)
                                                )
                                              ])
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.email",
                                      fn: function(ref) {
                                        var contact = ref.item
                                        return [
                                          contact.email && contact.obfuscated
                                            ? [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(contact.email) +
                                                    " "
                                                )
                                              ]
                                            : [
                                                contact.email
                                                  ? _c("qs-copy-to-clipboard", {
                                                      attrs: {
                                                        text: contact.email
                                                      }
                                                    })
                                                  : _vm._e(),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "qs-dark-blue--text",
                                                    attrs: {
                                                      href:
                                                        "mailto:" +
                                                        contact.email
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(contact.email)
                                                    )
                                                  ]
                                                )
                                              ]
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.home_phone",
                                      fn: function(ref) {
                                        var contact = ref.item
                                        return [
                                          contact.home_phone
                                            ? _c("qs-copy-to-clipboard", {
                                                attrs: {
                                                  text: _vm.$filters.phone(
                                                    contact.home_phone
                                                  )
                                                }
                                              })
                                            : _vm._e(),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "qs-dark-blue--text",
                                              attrs: {
                                                href:
                                                  "tel:" + contact.home_phone
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("phone")(
                                                    contact.home_phone
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.actions",
                                      fn: function(ref) {
                                        var contact = ref.item
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "d-flex" },
                                            [
                                              _vm.userHas("CONTACTS_RETRIEVE")
                                                ? _c("qs-icon-button", {
                                                    attrs: {
                                                      color: "qs-blue",
                                                      disabled:
                                                        contact.obfuscated,
                                                      icon: "mdi-magnify",
                                                      label:
                                                        "Consulter la fiche du contact",
                                                      small: "",
                                                      theme: "labase"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.goTo(
                                                          $event,
                                                          {
                                                            name: "ContactForm",
                                                            params: {
                                                              id: contact.id
                                                            }
                                                          }
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm.userHas(
                                                "CONTACT_EVENTS_UPDATE"
                                              )
                                                ? _c("qs-icon-button", {
                                                    staticClass: "ml-3",
                                                    attrs: {
                                                      color: "qs-blue",
                                                      icon: "mdi-pencil",
                                                      label:
                                                        "Modifier la participation",
                                                      small: "",
                                                      theme: "labase"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.showContactEventModal(
                                                          contact
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm.userHas(
                                                "CONTACT_EVENTS_DELETE"
                                              )
                                                ? _c("qs-icon-button", {
                                                    staticClass: "ml-3",
                                                    attrs: {
                                                      color: "qs-red",
                                                      icon: "mdi-delete",
                                                      label:
                                                        "Supprimer la participation",
                                                      small: "",
                                                      theme: "labase"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.storeContactAndShowConfirmDeleteContactEventModal(
                                                          contact
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2374760241
                                )
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2770931662
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("add-single-contact-modal", {
        attrs: {
          value: _vm.showAddSingleContactModal,
          loading: _vm.addSingleContactModalLoading
        },
        on: {
          "update:value": function($event) {
            _vm.showAddSingleContactModal = $event
          },
          add: _vm.addSingleContactToEvent
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [_vm._v("Ajouter des participant·es")]
            },
            proxy: true
          },
          {
            key: "subtitle",
            fn: function() {
              return [_vm._v("à un événement")]
            },
            proxy: true
          },
          {
            key: "prepend",
            fn: function() {
              return [
                _c(
                  "v-alert",
                  {
                    staticClass: "body-2",
                    attrs: { border: "left", outlined: "", tile: "" }
                  },
                  [
                    _vm._v(
                      " Vous pouvez aussi ajouter des participant·es en masse à partir de votre "
                    ),
                    _c("router-link", { attrs: { to: "/contacts" } }, [
                      _vm._v("liste de contacts")
                    ]),
                    _vm._v(" via "),
                    _c("strong", [
                      _vm._v(
                        "Actions > Ajouter comme participant·es à un événement"
                      )
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("contact-event-modal", {
        attrs: {
          "disable-presence": !_vm.eventStartsSoon,
          value: _vm.contactEventModal.visible,
          model: _vm.contactEventModal.model,
          loading: _vm.contactEventModal.loading
        },
        on: {
          "update:value": function($event) {
            return _vm.$set(_vm.contactEventModal, "visible", $event)
          },
          "click:save": _vm.saveContactEvent
        }
      }),
      _c("qs-confirmation-modal", {
        attrs: {
          value: _vm.dialog.value,
          color: _vm.dialog.color,
          icon: _vm.dialog.icon,
          loading: _vm.loading,
          message: _vm.dialog.message,
          title: _vm.dialog.title
        },
        on: {
          "click:cancel": function() {
            return (_vm.dialog.value = false)
          },
          "click:confirm": _vm.dialog.callback
        }
      }),
      _c("qs-confirmation-modal", {
        attrs: {
          loading: _vm.deleteContactEventModal.loading,
          message: _vm.deleteContactEventModal.contact
            ? _vm.deleteContactEventModal.contact.full_name +
              "\n        (" +
              _vm.deleteContactEventModal.contact.v1_contact_id +
              ")"
            : "",
          title: "Êtes-vous sûr de vouloir retirer ce contact de cet événement?"
        },
        on: {
          "click:cancel": function($event) {
            _vm.deleteContactEventModal.visible = false
          },
          "click:confirm": _vm.deleteContactEvent
        },
        model: {
          value: _vm.deleteContactEventModal.visible,
          callback: function($$v) {
            _vm.$set(_vm.deleteContactEventModal, "visible", $$v)
          },
          expression: "deleteContactEventModal.visible"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }