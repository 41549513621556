























































































































































































































































































































































































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';

import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsCard from 'qs_vuetify/src/components/QsCard.vue';
import QsConfirmationModal from 'qs_vuetify/src/components/Dialog/QsConfirmationModal.vue';
import QsCopyToClipboard from 'qs_vuetify/src/components/QsCopyToClipboard.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsDurationField from 'qs_vuetify/src/components/Fields/QsDurationField.vue';
import QsEventsIntentionIndicator
  from 'qs_vuetify/src/components/Indicators/QsEventsIntentionIndicator.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsHtmlEditor from 'qs_vuetify/src/components/Forms/QsHtmlEditor.vue';
import QsIconButton from 'qs_vuetify/src/components/QsIconButton.vue';
import LocationField from '@/components/LocationField.vue';
import QsProgress from 'qs_vuetify/src/components/QsProgress.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import I18nMixin from 'qs_vuetify/src/mixins/I18nMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';
import NavigationMixin from 'qs_vuetify/src/mixins/NavigationMixin';

import ContactEventModal from '@/components/Event/ContactEventModal.vue';

import {
  ButtonProps,
  ExportMimeType,
  Form,
  SelectItem,
} from 'qs_vuetify/src/types/components';
import {
  ContactEvent,
  EventStats,
  PersistedContact,
  PersistedEvent,
  PersistedInstance,
  User,
} from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { RestParams, FiltersDefinition } from 'qs_vuetify/src/types/states';

import axios from 'qs_vuetify/src/plugins/axios';

import AddSingleContactModal from '@/components/Dialog/AddSingleContactModal.vue';
import ItemNavigation from '@/components/ItemNavigation.vue';

type ContactContactEvent = PersistedContact & ContactEvent & { contact_event_id: number };

const auth: any = namespace('auth');
const contacts: any = namespace('contacts');
const contactEvents: any = namespace('contact_events');
const events: any = namespace('events');
const global: any = namespace('global');
const instances: any = namespace('instances');
const view: any = namespace('eventsView');

@Component({
  beforeRouteLeave(to, from, next) {
    this.$store.commit('contacts/data', []);
    this.$store.commit('contacts/loaded', false);
    next();
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
  components: {
    AddSingleContactModal,
    ContactEventModal,
    ItemNavigation,
    QsButton,
    QsCard,
    QsConfirmationModal,
    QsCopyToClipboard,
    QsDataTable,
    QsDurationField,
    QsEventsIntentionIndicator,
    QsFilters,
    QsFormBuilder,
    QsHtmlEditor,
    QsIconButton,
    LocationField,
    QsProgress,
  },
})
export default class EventForm extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
  I18nMixin,
  ListMixin,
  NavigationMixin,
) {
  @auth.Getter user!: User;

  @contacts.Getter('data') contacts!: Array<PersistedContact>;
  @contacts.Getter exportUrl!: string;
  @contacts.Getter('loaded') contactsLoaded!: boolean;
  @contacts.Getter('loading') contactsLoading!: boolean;
  @contacts.Getter('total') contactsTotal!: number;
  @contacts.Mutation('data') setData!: (a: Array<PersistedContact>) => void;

  @contactEvents.Getter('filtersDefinition') contactEventsFiltersDefinition!: FiltersDefinition | null;
  @contactEvents.Action('patch') patchContactEvent!: ({ id: number; field: string; value: any });

  @events.Getter data!: Array<PersistedEvent>;
  @events.Getter error!: ErrorResponse;
  @events.Getter form!: Form;
  @events.Getter initialItem!: string;
  @events.Getter item!: PersistedEvent;
  @events.Getter loading!: boolean;
  @events.Getter loaded!: boolean;
  @events.Getter slug!: string;
  @events.Getter stats!: EventStats;
  @events.Getter statsLoaded!: EventStats;
  @events.Getter total!: number;
  @events.Mutation('item') syncItem!: any;

  @global.Mutation addNotification!: Function;
  @global.Mutation removeNotification!: Function;
  @global.Mutation setPreviousLocation!: (location: RawLocation | null) => void;

  @instances.Getter('data') instances!: Array<PersistedInstance>;

  @view.Getter contactsOptions!: any;
  @view.Getter contactsParams!: RestParams;
  @view.Getter params!: RestParams;
  @view.Mutation setContactsOptions!: any;
  @view.Mutation setContactsParams!: any;
  @view.Mutation setParams!: any;

  @Prop({ required: true, type: [String, Number] }) id!: string | number;
  @Prop({ required: false, type: String, default: 'events' }) modelName!: string;

  contactsFiltersOpen = false;

  contactsHeaders = [
    {
      text: 'Intention',
      value: 'intention',
      sortable: false,
      align: 'center',
    },
    {
      text: 'Confirmation',
      value: 'event_confirmations',
      sortable: false,
      align: 'center',
    },
    { text: 'Présence', value: 'presence', sortable: false },
    { text: 'Contact', value: 'full_name', sortable: false },
    { text: 'Courriel', value: 'email', sortable: false },
    { text: 'Téléphone', value: 'home_phone', sortable: false },
    { text: '', value: 'actions', sortable: false },
  ];

  contactEventModal: {
    model: ContactContactEvent | null;
    loading: boolean;
    visible: boolean;
  } = {
    model: null,
    loading: false,
    visible: false,
  };

  deleteContactEventModal: {
    contact: PersistedContact | null;
    loading: boolean;
    visible: boolean;
  } = {
    contact: null,
    loading: false,
    visible: false,
  };

  addSingleContactModalLoading = false;
  showAddSingleContactModal = false;
  showFormDefinitionWarningModal = false;
  userWarnedAboutFormDefinition = false;

  get contactsFilters(): FiltersDefinition {
    if (!this.contactEventsFiltersDefinition) {
      return {};
    }

    return {
      q: { type: 'text' },
      'contact_events.event_confirmations': this.contactEventsFiltersDefinition.event_confirmations,
      'contact_events.intention': this.contactEventsFiltersDefinition.intention,
      'contact_events.presence': this.contactEventsFiltersDefinition.presence,
    };
  }

  get contactsWithoutIntentionTotal(): number {
    return this.contacts.filter((c) => !c.intention).length;
  }

  get eventStartsSoon(): boolean {
    if (this.item && this.item.start_at) {
      return this.$dayjs(this.item.start_at).subtract(2, 'hour').isBefore(this.$dayjs());
    }

    return false;
  }

  get formOrder(): string[] {
    if (this.user?.superadmin) {
      return [
        'instance_id',
        'visibility',
        'start_at',
        'duration',
        'should_send_email_reminder',
      ];
    }

    return [
      'visibility',
      'start_at',
      'duration',
    ];
  }

  get instanceIdSelectItems(): Array<SelectItem<null | number>> {
    return [
      { text: 'Nationale', value: null },
      ...this.instances.map((i) => ({ text: i.name, value: i.id })),
    ];
  }

  get presenceStats(): any[] {
    const items = [
      { color: 'green', key: 'presences.present', value: this.stats ? this.stats?.presences?.present : 0 },
      { color: 'red', key: 'presences.absent', value: this.stats ? this.stats?.presences?.absent : 0 },
    ];

    if (this.stats?.presences?.null) {
      items.push({ color: 'light', key: 'presences.null', value: this.stats ? this.stats.presences.null : 0 });
    }

    return items;
  }

  get viewParams() {
    return {
      events: {
        fields: [
          'id',
          'accessibility',
          'accessibility_notes',
          'description',
          'duration',
          'email_reminder_message',
          'instance_id',
          'location_name',
          'location.google_place_address',
          'location.google_place_id',
          'location.google_place_name',
          'location.point',
          'should_send_email_reminder',
          'start_at',
          'status',
          'title',
          'visibility',
        ].join(','),
        with_deleted: true,
      },
      contacts: {
        ...ListMixin.buildListState(this.contactsOptions, this.contactsParams),
        fields: [
          'contact_event_id',
          'intention',
          'event_confirmations.*',
          'presence',
          'email',
          'full_name',
          'home_phone',
          'v1_contact_id',
        ].join(','),
      },
    };
  }

  mounted() {
    this.setActions();
    this.setGlobalSubtitle();

    if (this.instances.length < 1) {
      this.$store.dispatch('instances/index', {
        fields: 'name',
        per_page: '*',
      });
    }
  }

  afterSave() {
    this.setGlobalSubtitle();
    this.$store.commit('global/addNotification', {
      color: 'success',
      message: 'Modifications enregistrées.',
      timeout: 1000,
    });
  }

  async loadNextPage() {
    if (typeof this.params.page === 'number') {
      this.setParams({
        ...this.params,
        page: this.params.page + 1,
      });
      this.$store.commit('global/subtitle', 'Chargement...');
      this.$emit('updateHead');
      await this.$store.dispatch('events/loadPage', this.params);
    }
  }

  @Watch('contacts.length')
  onContactsLengthChanged(length: number) {
    if (length === 0 && this.contactsOptions.page !== 1) {
      this.setContactsOptions({ page: 1 });
    }
  }

  @Watch('hasChanged')
  onHasChangedChanged() {
    this.setActions();
  }

  @Watch('itemReady')
  async onItemReadyChanged(ready: boolean) {
    if (ready) {
      this.setGlobalSubtitle();
      this.setActions();
    }
  }

  @Watch('loading')
  onLoadingChanged() {
    this.setActions();
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.reloadDataRoutesData();
    this.setGlobalSubtitle();
    this.setActions();
    this.$store.commit('events/item', null);
  }

  // eslint-disable-next-line class-methods-use-this
  get contactsExportFields(): string[] {
    return [
      [
        'id',
        'status',
        'first_name',
        'last_name',
        'v1_contact_id',
        'district.name',
        'gender',
        'birthdate',
        'address',
        'apartment',
        'city',
        'postal_code',
        'home_phone',
        'email',
      ],
      'presence',
      'intention',
      'event_confirmations.id',
      'event_confirmations.comment',
      'event_confirmations.created_at',
    ].flat();
  }

  async addSingleContactToEvent(contact: PersistedContact) {
    this.addSingleContactModalLoading = true;

    try {
      await axios.put(`/events/${this.item.id}/contacts/${contact.id}`, {}, {
        params: {
          fields: [
            'contact_event_id',
            'intention',
            'presence',
            'email',
            'full_name',
            'home_phone',
            'v1_contact_id',
          ].join(','),
        },
      });

      this.addNotification({
        color: 'success',
        message: `${contact.full_name} a été ajouté·e aux participant·es`,
      });

      this.showAddSingleContactModal = false;

      this.reloadDataRoutesData(['contacts.index', 'events.stats']);
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: `Erreur lors de l'ajout de ${contact.full_name} aux destinataires`,
      });
    } finally {
      this.addSingleContactModalLoading = false;
    }
  }

  async deleteContactEvent() {
    const {
      contact,
    } = this.deleteContactEventModal;

    if (!contact) {
      return;
    }

    this.deleteContactEventModal.loading = true;

    try {
      await axios.delete(`/events/${this.id}/contacts/${contact.id}`, {
        params: {
          fields: [
            'contact_event_id',
            'intention',
            'presence',
            'email',
            'full_name',
            'home_phone',
            'v1_contact_id',
          ].join(','),
        },
      });

      this.reloadDataRoutesData(['contact_events.index']);

      this.addNotification({
        color: 'success',
        message: `${contact.full_name} a été retiré des destinataires.`,
      });
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: 'Erreur lors du retrait du destinataire.',
      });
    } finally {
      this.deleteContactEventModal.contact = null;
      this.deleteContactEventModal.loading = false;
      this.deleteContactEventModal.visible = false;
    }
  }

  async exportContactsCsv() {
    await this.exportContacts('text/csv');
  }

  async exportContactsXls() {
    await this.exportContacts('application/vnd.ms-excel');
  }

  patchListContact(contact: PersistedContact, field: string, value: any) {
    const newContact = {
      ...contact,
      [field]: value,
    };

    const newData = [
      ...this.contacts,
    ];

    const index = newData.indexOf(contact);
    newData.splice(index, 1, newContact);

    this.setData(newData);

    this.reloadDataRoutesData(['events.stats']);
  }

  resetViewParams() {
    this.setContactsParams({});
  }

  async saveContactEvent(data: ContactContactEvent) {
    this.contactEventModal.loading = true;

    try {
      await axios.put(`/contact_events/${data.contact_event_id}`, data, {
        params: {
          fields: '*',
        },
      });
      this.contactEventModal.visible = false;
      this.reloadDataRoutesData(['contacts.index', 'events.stats']);
    } finally {
      this.contactEventModal.loading = false;
    }
  }

  setActions() {
    const actions: Array<ButtonProps> = [];

    if (this.userHas('EVENTS_UPDATE')) {
      actions.push({
        onClick: this.submit,
        color: 'primary',
        disabled: !this.hasChanged || this.loading,
        icon: '$qs-save',
        tooltip: 'Enregistrer',
      });
    }

    if (this.item && this.item.visibility === 'public') {
      actions.push({
        color: 'qs-yellow',
        disabled: this.loading,
        href: `${process.env.VUE_APP_MOUVEMENT_URL}/carte/${this.item.id}`,
        icon: 'mdi-map-marker-multiple',
        tooltip: 'Voir sur Mouvement',
      });
    }

    if (this.item && this.userHas('EVENTS_DELETE')) {
      if (this.item.deleted_at) {
        actions.push({
          onClick: async () => {
            await this.$store.dispatch('events/restore', { id: this.item.id });
            await this.reloadDataRoutesData();
            this.setActions();
          },
          color: 'success',
          icon: 'mdi-restore',
          tooltip: 'Restaurer',
        });

        if (this.userIsSuperadmin) {
          actions.push({
            onClick: async () => {
              await this.confirmThenForceDeleteItem(
                '',
                `Êtes-vous certain·e de vouloir <strong>supprimer cet événement</strong>?
                Cette opération ne peut pas être annulée. Toutes les données associées
                seront perdues.`,
                this.item.id,
              );
              await this.reloadDataRoutesData();
              this.setActions();
            },
            color: 'error',
            icon: 'mdi-delete-forever',
            tooltip: 'Supprimer',
          });
        }
      } else {
        actions.push({
          onClick: async () => {
            await this.confirmThenDeleteItem(
              'Archiver un événement',
              `Êtes-vous certain·e de vouloir <strong>archiver cet événement</strong>?
              Vous ne pourrez plus ajouter des participants, mais les données seront conservées.`,
            );
            await this.reloadDataRoutesData();
            this.setActions();
          },
          color: 'error',
          icon: 'mdi-delete',
        });
      }
    }

    this.$store.commit('global/actions', actions);
  }

  setGlobalSubtitle() {
    if (this.itemReady) {
      this.$store.commit('global/subtitle', this.item?.name);
    }
    this.$emit('updateHead');
  }

  showContactEventModal(contact: any) {
    this.contactEventModal.visible = true;
    this.contactEventModal.model = contact;
  }

  storeContactAndShowConfirmDeleteContactEventModal(contact: PersistedContact) {
    this.deleteContactEventModal.contact = contact;
    this.deleteContactEventModal.visible = true;
  }

  updateContactsFilters(name: string, value: any) {
    const newParams: RestParams = {
      ...this.contactsParams,
    };

    if (value) {
      newParams[name] = value;
    } else {
      delete newParams[name];
    }

    newParams.page = 1;

    this.setContactsParams(newParams);
  }

  private async exportContacts(mimeType: ExportMimeType) {
    const params = {
      ...this.contactsParams,
      prefix: `/events/${this.item.id}`,
      fields: this.contactsExportFields.join(','),
    };

    const generationNotification = {
      color: 'warning',
      message: 'Génération de votre fichier en cours...',
    };
    this.addNotification(generationNotification);

    await this.$store.dispatch('contacts/export', {
      params,
      mimeType,
    });

    this.removeNotification(generationNotification);
    this.addNotification({
      color: 'success',
      message: 'Génération terminée!',
      timeout: -1,
      action: () => {
        document.location.href = this.exportUrl;
      },
    });
  }
}
