var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.nationalEvent
    ? _c(
        "v-container",
        { staticClass: "national-event-delegation", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { md: "6", lg: "3", "order-md": "1" } }, [
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-column",
                    staticStyle: { gap: "24px" }
                  },
                  [
                    _c(
                      "v-card",
                      { staticClass: "d-flex flex-column" },
                      [
                        _c("v-card-title", [_vm._v("Informations générales")]),
                        _c("v-card-text", {
                          domProps: {
                            innerHTML: _vm._s(_vm.nationalEvent.description)
                          }
                        })
                      ],
                      1
                    ),
                    _vm.nationalEvent && _vm.nationalEvent.important_dates
                      ? _c(
                          "v-card",
                          [
                            _c("v-card-title", [_vm._v(" Dates importantes ")]),
                            _c("v-card-text", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.nationalEvent.important_dates
                                )
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _c(
                "v-col",
                {
                  attrs: { md: "12", lg: "6", "order-lg": "2", "order-md": "3" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column",
                      staticStyle: { gap: "24px" }
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-title",
                            [
                              _c("v-spacer"),
                              _c(
                                "qs-button",
                                {
                                  attrs: { disabled: !!_vm.item },
                                  on: { click: _vm.addEmptyItem }
                                },
                                [_vm._v("Ajouter une ligne")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c("v-data-table", {
                                staticClass: "elevation-1",
                                attrs: {
                                  headers: _vm.tableHeaders,
                                  "hide-default-header": "",
                                  "hide-default-footer": "",
                                  items:
                                    _vm.item && !_vm.item.id
                                      ? [_vm.item].concat(_vm.data)
                                      : _vm.data,
                                  "items-per-page": -1,
                                  loading: _vm.loading,
                                  "loading-text":
                                    "Chargement de la délégation...",
                                  "no-data-text": "Pas de délégation inscrite"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item.id",
                                      fn: function(ref) {
                                        var participation = ref.item
                                        return [
                                          _vm.hasChanged &&
                                          _vm.item &&
                                          _vm.item.id === participation.id
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "red--text font-weight-bold text-h3"
                                                },
                                                [_vm._v("*")]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.contact",
                                      fn: function(ref) {
                                        var participation = ref.item
                                        var value = ref.value
                                        return [
                                          _vm.item &&
                                          participation.id === _vm.item.id
                                            ? _c("qs-relation-field", {
                                                attrs: {
                                                  filter: function() {
                                                    return true
                                                  },
                                                  item: participation,
                                                  label: "Membre",
                                                  name: "contact_id",
                                                  placeholder:
                                                    "Sélectionnez un membre",
                                                  query:
                                                    _vm.contactQueryDefinition,
                                                  value:
                                                    participation.contact_id
                                                },
                                                on: {
                                                  input: function($event) {
                                                    return _vm.updateItemKey(
                                                      "contact",
                                                      $event
                                                    )
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "selection",
                                                      fn: function(ref) {
                                                        var selection = ref.item
                                                        return [
                                                          _c(
                                                            "national-event-delegation-participant-select-item",
                                                            {
                                                              attrs: {
                                                                "should-be-member":
                                                                  selection.type ===
                                                                  "delegate",
                                                                value: selection
                                                              }
                                                            }
                                                          )
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item",
                                                      fn: function(ref) {
                                                        var listItem = ref.item
                                                        return [
                                                          _c(
                                                            "national-event-delegation-participant-select-item",
                                                            {
                                                              attrs: {
                                                                "should-be-member":
                                                                  listItem.type ===
                                                                  "delegate",
                                                                value: listItem
                                                              }
                                                            }
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            : _c(
                                                "national-event-delegation-participant-select-item",
                                                {
                                                  attrs: {
                                                    "should-be-member":
                                                      participation.type ===
                                                      "delegate",
                                                    value: value
                                                  }
                                                }
                                              )
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.type",
                                      fn: function(ref) {
                                        var participation = ref.item
                                        var value = ref.value
                                        return [
                                          _vm.item &&
                                          participation.id === _vm.item.id
                                            ? _c("qs-select-field", {
                                                attrs: {
                                                  items: _vm.participationTypes,
                                                  label: "Type",
                                                  name: "type",
                                                  value: value
                                                },
                                                on: {
                                                  input: function($event) {
                                                    return _vm.updateItemKey(
                                                      "type",
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            : _c(
                                                "div",
                                                { staticClass: "v-input" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm
                                                        .participationTypeLabels[
                                                        value
                                                      ]
                                                    )
                                                  )
                                                ]
                                              ),
                                          _vm.item &&
                                          participation.id === _vm.item.id &&
                                          _vm.userHas("NATIONAL_EVENTS_UPDATE")
                                            ? _c("qs-select-field", {
                                                attrs: {
                                                  items:
                                                    _vm.participationStates,
                                                  label: "Statut",
                                                  name: "state",
                                                  value: participation.state
                                                },
                                                on: {
                                                  input: function($event) {
                                                    return _vm.updateItemKey(
                                                      "state",
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            : _vm._e()
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.state",
                                      fn: function(ref) {
                                        var participation = ref.item
                                        var value = ref.value
                                        return [
                                          !_vm.item ||
                                          !_vm.item.id ||
                                          participation.id !== _vm.item.id
                                            ? _c(
                                                "div",
                                                { staticClass: "v-input" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm
                                                          .participationStateLabels[
                                                          value
                                                        ]
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.actions",
                                      fn: function(ref) {
                                        var participation = ref.item
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "d-flex" },
                                            [
                                              _vm.item &&
                                              _vm.item.id === participation.id
                                                ? _c("qs-button", {
                                                    attrs: {
                                                      disabled:
                                                        !_vm.hasChanged ||
                                                        (!participation.id &&
                                                          (!participation.contact ||
                                                            !participation.type)),
                                                      icon: !participation.id
                                                        ? "mdi-plus"
                                                        : "mdi-content-save",
                                                      label: !participation.id
                                                        ? "Ajouter"
                                                        : "Sauvegarder",
                                                      rounded: "",
                                                      small: ""
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.saveItemAndReloadData
                                                    }
                                                  })
                                                : _c("qs-button", {
                                                    attrs: {
                                                      disabled: _vm.loading,
                                                      icon: "mdi-pencil",
                                                      label: "Éditer",
                                                      rounded: "",
                                                      small: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.startEditItem(
                                                          participation
                                                        )
                                                      }
                                                    }
                                                  }),
                                              (_vm.item &&
                                                !_vm.item.id &&
                                                !participation.id) ||
                                              (_vm.item &&
                                                _vm.item.id &&
                                                _vm.item.id ===
                                                  participation.id)
                                                ? _c("qs-button", {
                                                    attrs: {
                                                      icon: "mdi-cancel",
                                                      label: "Annuler",
                                                      small: ""
                                                    },
                                                    on: {
                                                      click: _vm.clearEmptyItem
                                                    }
                                                  })
                                                : _c("qs-button", {
                                                    attrs: {
                                                      disabled: _vm.loading,
                                                      icon: "mdi-delete",
                                                      label: "Supprimer",
                                                      small: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deleteParticipation(
                                                          participation
                                                        )
                                                      }
                                                    }
                                                  }),
                                              participation.id
                                                ? _c("qs-button", {
                                                    attrs: {
                                                      icon: "mdi-eye",
                                                      label:
                                                        "Voir la fiche de contact",
                                                      small: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.goToContactForm(
                                                          $event,
                                                          participation.contact_id
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2210203668
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "v-col",
                {
                  attrs: { md: "6", lg: "3", "order-lg": "3", "order-md": "2" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column",
                      staticStyle: { gap: "24px" }
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c("v-card-title", [
                            _vm._v("Taille de la délégation")
                          ]),
                          _c(
                            "v-card-text",
                            [
                              _vm.instanceDelegationSize === null
                                ? _c("p", { staticStyle: {} }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$i18n.t(
                                            "national_events.delegation_size_not_specified"
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                : _c("p", { staticStyle: {} }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$i18n.tc(
                                            "national_events.can_delegate",
                                            _vm.instanceDelegationSize,
                                            { name: _vm.instanceName }
                                          )
                                        ) +
                                        " "
                                    )
                                  ]),
                              _vm.instanceDelegationSize > 0
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-center font-weight-bold mb-2",
                                      staticStyle: {
                                        "font-size": "48px",
                                        "line-height": "48px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$i18n.tc(
                                              "national_events.people",
                                              _vm.instanceDelegationSize
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.delegationDocuments.length > 0
                                ? _c(
                                    "v-list",
                                    { attrs: { rounded: "" } },
                                    _vm._l(_vm.delegationDocuments, function(
                                      ref,
                                      i
                                    ) {
                                      var href = ref.href
                                      var title = ref.title
                                      var subtitle = ref.subtitle
                                      return _c(
                                        "v-list-item",
                                        {
                                          key: "document-" + i,
                                          attrs: { href: href }
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "info" } },
                                                [_vm._v("mdi-attachment")]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(_vm._s(title))
                                              ]),
                                              _c("v-list-item-subtitle", [
                                                _vm._v(_vm._s(subtitle))
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card",
                        {
                          staticClass: "national-event-delegation__validations"
                        },
                        [
                          _c("v-card-title", [_vm._v("Validations")]),
                          _c("v-card-text", [
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "d-flex align-start mb-4" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "national-event-delegation__validations__label"
                                    },
                                    [_vm._v("Parité")]
                                  ),
                                  _c("qs-boolean-indicator", {
                                    staticClass: "ml-2",
                                    attrs: { value: _vm.hasParity }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "ml-2 national-event-delegation__validations__explanation"
                                    },
                                    [
                                      _vm.parityMessage
                                        ? _c("small", [
                                            _vm._v(_vm._s(_vm.parityMessage))
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("hr"),
                              _c(
                                "div",
                                { staticClass: "d-flex align-start" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "national-event-delegation__validations__label"
                                    },
                                    [_vm._v("Genres")]
                                  ),
                                  _c("qs-boolean-indicator", {
                                    staticClass: "ml-2",
                                    attrs: { value: _vm.hasGenders }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "ml-2 national-event-delegation__validations__explanation"
                                    },
                                    [
                                      !_vm.hasGenders
                                        ? _c("small", [
                                            _vm._v(
                                              " Assurez-vous que le genre de tou·tes les délégué·es est bien configuré: celui-ci est requis pour déterminer l'alternance des tours de parole. "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("hr"),
                              _c(
                                "div",
                                { staticClass: "d-flex align-start" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "national-event-delegation__validations__label"
                                    },
                                    [_vm._v("Courriels")]
                                  ),
                                  _c("qs-boolean-indicator", {
                                    staticClass: "ml-2",
                                    attrs: { value: _vm.hasEmails }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "ml-2 national-event-delegation__validations__explanation"
                                    },
                                    [
                                      !_vm.hasEmails
                                        ? _c("small", [
                                            _vm._v(
                                              " Assurez-vous que tous les membres de votre délégation ont un courriel: celui-ci est requis pour s'identifier lors de l'événement et sera utilisé pour communiquer avec les participant·es. "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("hr"),
                              _c(
                                "div",
                                { staticClass: "d-flex align-start" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "national-event-delegation__validations__label"
                                    },
                                    [_vm._v("Adhésions")]
                                  ),
                                  _c("qs-boolean-indicator", {
                                    staticClass: "ml-2",
                                    attrs: { value: _vm.hasMemberships }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "ml-2 national-event-delegation__validations__explanation"
                                    },
                                    [
                                      !_vm.hasMemberships
                                        ? _c("small", [
                                            _vm._v(
                                              " Assurez-vous que tous les membres de votre délégation sont membre en règle de Québec solidaire. "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("hr"),
                              _c(
                                "div",
                                { staticClass: "d-flex align-start" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "national-event-delegation__validations__label"
                                    },
                                    [_vm._v("Taille")]
                                  ),
                                  _vm.instanceDelegationSize === null
                                    ? _c("qs-boolean-indicator", {
                                        staticClass: "ml-2",
                                        attrs: { value: null }
                                      })
                                    : _c("qs-boolean-indicator", {
                                        staticClass: "ml-2",
                                        attrs: {
                                          value:
                                            (_vm.delegatesCount > 0 ||
                                              _vm.instanceDelegationSize ===
                                                0) &&
                                            _vm.delegatesCount <=
                                              _vm.instanceDelegationSize
                                        }
                                      }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "ml-2 national-event-delegation__validations__explanation"
                                    },
                                    [
                                      _vm.instanceDelegationSize === null
                                        ? _c("small")
                                        : _vm.delegatesCount >
                                          _vm.instanceDelegationSize
                                        ? _c("small", [
                                            _vm._v(
                                              " Vous avez trop de délégué·es (" +
                                                _vm._s(_vm.delegatesCount) +
                                                "). "
                                            )
                                          ])
                                        : _vm.delegatesCount === 0
                                        ? _c("small", [
                                            _vm._v(
                                              " Vous devez déléguer au moins une personne. "
                                            )
                                          ])
                                        : _c("small", [
                                            _vm._v(
                                              " Vous avez " +
                                                _vm._s(
                                                  _vm.$i18n.tc(
                                                    "national_events.delegates_count",
                                                    _vm.delegatesCount,
                                                    {
                                                      count: _vm.delegatesCount
                                                    }
                                                  )
                                                ) +
                                                ". "
                                            )
                                          ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _vm.instanceDocuments.length > 0
                        ? _c(
                            "v-card",
                            [
                              _c("v-card-title", [
                                _vm._v("Documents de l'instance")
                              ]),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-list",
                                    { attrs: { rounded: "" } },
                                    _vm._l(_vm.instanceDocuments, function(
                                      ref,
                                      i
                                    ) {
                                      var href = ref.href
                                      var title = ref.title
                                      var subtitle = ref.subtitle
                                      return _c(
                                        "v-list-item",
                                        {
                                          key: "document-" + i,
                                          attrs: { href: href }
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "info" } },
                                                [_vm._v("mdi-attachment")]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(_vm._s(title))
                                              ]),
                                              _c("v-list-item-subtitle", [
                                                _vm._v(_vm._s(subtitle))
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c("qs-confirmation-modal", {
            attrs: {
              value: _vm.dialog.value,
              color: _vm.dialog.color,
              icon: _vm.dialog.icon,
              loading: _vm.loading || _vm.dialog.loading,
              message: _vm.dialog.message,
              title: _vm.dialog.title
            },
            on: {
              "click:cancel": function() {
                return (_vm.dialog.value = false)
              },
              "click:confirm": _vm.dialog.callback
            }
          })
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "national-event-delegation__loading" },
        [
          _c("v-progress-circular", {
            attrs: { color: "primary", indeterminate: "" }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }