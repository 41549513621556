import Vue from 'vue';
import Component from 'vue-class-component';

import { getObjectValueByPath } from 'vuetify/lib/util/helpers';
import { DataTableCompareFunction } from 'vuetify';

import { MappableElectionResultsProperty } from '@/types/components';
import { SelectItem } from 'qs_vuetify/src/types/components';
import { BaseResultModel, ResultsOwningModel, PersistedParty } from 'qs_vuetify/src/types/models';

@Component
export default class ElectionResultsMixin extends Vue {
  activePartyDisplay: number | null = 2;
  activeStatsStyle: string | null = 'electors_count';

  parties: Array<SelectItem<number>> = [];

  /**
   *
   * @param segments
   * @returns PersistedParty[]
   */
  // eslint-disable-next-line class-methods-use-this
  accessiblePartiesFrom(segments: ResultsOwningModel[]): PersistedParty[] {
    // 1. Filtrer les SV avec des résultats / mapper les résultats
    const results: BaseResultModel[] = segments
      .filter((p) => p.results && p.results.length > 0)
      .flatMap((p) => p.results);

    // 2. Filtrer les partis avec des résultats / mapper les partis
    const parties: PersistedParty[] = (results.filter((r) => !!r.party) as BaseResultModel[])
      .map((r) => r.party);

    // 3. Utiliser new Set() pour générer une liste d'ids sans doublons (plus safe que des objets)
    const uniquePartiesIds: number[] = [...new Set(parties.map((p: PersistedParty) => p.id))];

    // 4. Retourner une liste de partis uniques
    return (uniquePartiesIds.map((id) => parties.find((p) => p.id === id)) as PersistedParty[]);
  }

  // eslint-disable-next-line class-methods-use-this
  customSort<T extends any = any>(
    items: T[],
    sortBy: string[],
    sortDesc: boolean[],
    locale: string,
    customSorters?: Record<string, DataTableCompareFunction<T>>,
  ) {
    const normallySortedColumns: string[] = [
      'actions',
      'municipality.name',
      'name',
      'polling_sector.name',
    ];
    const stringCollator = new Intl.Collator(locale, { sensitivity: 'accent', usage: 'sort' });
    if (sortBy === null || !sortBy.length) return items;


    return items.sort((a, b) => {
      for (let i = 0; i < sortBy.length; i += 1) {
        const sortKey = sortBy[i];
        let sortA = getObjectValueByPath(a, sortKey);
        let sortB = getObjectValueByPath(b, sortKey);

        if (sortDesc[i]) {
          [sortA, sortB] = [sortB, sortA];
        }

        /**
         * c.f. https://github.com/vuetifyjs/vuetify/blob/4468e3c442284b512729e7b89768fd8762c2e9c1/packages/vuetify/src/util/helpers.ts
         * tri normal de vuetify
         */
        if (normallySortedColumns.includes(sortKey)) {
          if (customSorters && customSorters[sortKey]) {
            const customResult = customSorters[sortKey](sortA, sortB);

            if (!customResult) {
              continue;
            }

            return customResult;
          }

          // Check if both cannot be evaluated
          if (sortA === null && sortB === null) {
            continue;
          }

          // Dates should be compared numerically
          if (sortA instanceof Date && sortB instanceof Date) {
            return sortA.getTime() - sortB.getTime();
          }

          [sortA, sortB] = [sortA, sortB].map((s) => (s || '').toString().toLocaleLowerCase());

          if (sortA !== sortB) {
            if (!Number.isNaN(sortA) && !Number.isNaN(sortB)) {
              return Number(sortA) - Number(sortB);
            }

            return stringCollator.compare(sortA, sortB);
          }
        } else {
          /**
          * Pour éviter d'avoir à overrider le slot de toutes les cellules,
          * on envoie les nombres correctement formattés en texte
          * (i.e. 0.296883674 --> 29.69 %)
          *
          * On doit les ré-interpréter comme nombre avant de les trier
          * avec une fonction custom.
          */
          return parseFloat(sortA.replace(' ', '')) - parseFloat(sortB.replace(' ', ''));
        }
      }

      return 0;
    });
  }

  /**
   *
   * @param segments
   * @param prop
   * @returns number
   */
  maxResultValueFrom(segments: ResultsOwningModel[], prop: MappableElectionResultsProperty | 'stats'): number {
    return segments
      .reduce((acc: number, val: ResultsOwningModel) => {
        if (prop === 'stats' && this.activeStatsStyle) {
          const value = (val.stats[this.activeStatsStyle] as number);

          if (value) {
            if (!acc || value > acc) {
              return value;
            }
          }
        } else if (val.results && Array.isArray(val.results)) {
          const result = val.results
            .find((r: BaseResultModel) => r.party && r.party.id === this.activePartyDisplay);

          if (result && result[prop]) {
            const value: number = (result[prop] as number);
            if (!acc || value > acc) {
              return value;
            }
          }
        }

        return acc;
      }, 0);
  }

  /**
   *
   * @param segments
   * @param prop
   * @returns number
   */
  minResultValueFrom(segments: ResultsOwningModel[], prop: MappableElectionResultsProperty | 'stats'): number {
    return segments
      .reduce((acc: number, val: ResultsOwningModel) => {
        if (prop === 'stats' && this.activeStatsStyle) {
          const value = (val.stats[this.activeStatsStyle] as number);

          if (value) {
            if (value < acc) {
              return value;
            }
          }
        } else if (val.results && Array.isArray(val.results)) {
          const { valid_ballots } = val;
          const result = val.results
            .find((r: BaseResultModel) => r.party && r.party.id === this.activePartyDisplay);

          if (result && prop in result && valid_ballots && valid_ballots > 0) {
            const value: number = (result[prop] as number);
            if (value < acc) {
              return value;
            }
          }
        }

        return acc;
      }, 10000);
  }

  // eslint-disable-next-line class-methods-use-this
  winnerFrom(segment: ResultsOwningModel): PersistedParty | null {
    const max = Math.max(...segment.results.map((r) => r.votes));
    const winners = segment.results.filter((r) => r.votes >= max);

    if (winners.length === 1) {
      return winners[0].party;
    }

    return null;
  }
}
