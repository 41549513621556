import { Module } from 'vuex';
import { RootState } from 'qs_vuetify/src/types/states';

import { CallCampaignsViewState } from '@/types/states';

export const callCampaignsViewState: Module<CallCampaignsViewState, RootState> = {
  namespaced: true,
  state: {
    callbackExchangeId: null,
    contactExchangesOptions: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10,
    },
    contactExchangesParams: {
      page: 1,
      per_page: 20,
    },
    contactsOptions: {
      sortBy: ['full_name'],
      sortDesc: [false],
      page: 1,
      itemsPerPage: 10,
    },
    contactsParams: {
      page: 1,
      per_page: 20,
    },
    currentContactExchangeId: -1,
    params: {
      status: 'draft,active',
      page: 1,
      per_page: 20,
    },
    recallSameDay: false,
    requestedPaperFormContactIds: [],
    seenContactIds: [],
    selectedItems: [],
    showExtraInformation: true,
    usersModal: false,
  },
  getters: {
    callbackExchangeId(state) {
      return state.callbackExchangeId;
    },
    contactExchangesOptions(state) {
      return state.contactExchangesOptions;
    },
    contactExchangesParams(state) {
      return state.contactExchangesParams;
    },
    contactsOptions(state) {
      return state.contactsOptions;
    },
    contactsParams(state) {
      return state.contactsParams;
    },
    currentContactExchangeId(state) {
      return state.currentContactExchangeId;
    },
    params(state) {
      return state.params;
    },
    recallSameDay(state) {
      return state.recallSameDay;
    },
    requestedPaperFormContactIds(state) {
      return state.requestedPaperFormContactIds;
    },
    seenContactIds(state) {
      return state.seenContactIds;
    },
    selectedItems(state) {
      return state.selectedItems;
    },
    showExtraInformation(state) {
      return state.showExtraInformation;
    },
    usersModal(state) {
      return state.usersModal;
    },
  },
  mutations: {
    addRequestedPaperFormContactId(state, requestedPaperFormContactId) {
      const requestedPaperFormContactIds = [...state.seenContactIds, requestedPaperFormContactId];

      state.requestedPaperFormContactIds = [...new Set(requestedPaperFormContactIds)];
    },
    addSelectedItem(state, selectedItem) {
      if (!state.selectedItems.find((i) => i.id === selectedItem.id)) {
        state.selectedItems.push({
          ...selectedItem,
        });
      }
    },
    addSeenContactId(state, seenContactId) {
      const seenContactIds = [...state.seenContactIds, seenContactId];

      state.seenContactIds = [...new Set(seenContactIds)];
    },
    clearRequestedPaperFormContactIds(state) {
      state.requestedPaperFormContactIds = [];
    },
    clearSeenContactIds(state) {
      state.seenContactIds = [];
    },
    removeSeenContactId(state, seenContactId) {
      const index = state.seenContactIds.indexOf(seenContactId);

      if (index !== -1) {
        state.seenContactIds.splice(index, 1);
      }
    },
    removeSelectedItem(state, selectedItem) {
      const index = state.selectedItems.findIndex((i) => i.id === selectedItem.id);
      if (index !== -1) {
        state.selectedItems.splice(index, 1);
      }
    },
    setCallbackExchangeId(state, value) {
      state.callbackExchangeId = value;
    },
    setContactExchangesOptions(state, options) {
      state.contactExchangesOptions = options;
    },
    setContactExchangesParams(state, params) {
      state.contactExchangesParams = params;
    },
    setContactsOptions(state, options) {
      state.contactsOptions = options;
    },
    setContactsParams(state, contactsParams) {
      state.contactsParams = contactsParams;
    },
    showExtraInformation(state, showExtraInformation) {
      state.showExtraInformation = showExtraInformation;
    },
    setParams(state, params) {
      state.params = params;
    },
    setRecallSameDay(state, payload) {
      state.recallSameDay = payload;
    },
    setSelectedItems(state, selectedItems) {
      state.selectedItems.splice(
        0,
        state.selectedItems.length,
        ...selectedItems,
      );
    },
    setUsersModal(state, value) {
      state.usersModal = value;
    },
  },
};

export default callCampaignsViewState;
