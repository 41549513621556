





























































































































































































































































































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';

import QsBooleanIndicator from 'qs_vuetify/src/components/Indicators/QsBooleanIndicator.vue';
import QsConfirmationModal from 'qs_vuetify/src/components/Dialog/QsConfirmationModal.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';
import QsSelectField from 'qs_vuetify/src/components/Fields/QsSelectField.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ErrorMixin from 'qs_vuetify/src/mixins/ErrorMixin';
import NavigationMixin from 'qs_vuetify/src/mixins/NavigationMixin';

import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';

import type { DialogProps, SelectItem } from 'qs_vuetify/src/types/components';
import type {
  Instance,
  NationalEvent,
  NationalEventParticipation,
  NationalEventParticipationState,
  NationalEventParticipationType,
  PersistedNationalEventParticipation,
} from 'qs_vuetify/src/types/models';
import type { ErrorResponse } from 'qs_vuetify/src/types/responses';
import type { RestParams } from 'qs_vuetify/src/types/states';

import NationalEventDelegationParticipantSelectItem from '@/components/NationalEvents/NationalEventDelegationParticipantSelectItem.vue';

const global: any = namespace('global');
const nationalEvents: any = namespace('national_events');
const nationalEventParticipations: any = namespace('national_event_participations');

interface Document { href: string; title: string; subtitle: string }

@Component({
  components: {
    NationalEventDelegationParticipantSelectItem,
    QsBooleanIndicator,
    QsButton,
    QsConfirmationModal,
    QsRelationField,
    QsSelectField,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = title;
      if (subtitle) {
        inner = `${inner} | ${subtitle}`;
      }
      return { inner };
    },
  },
})
export default class NationalEventDelegation extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  ErrorMixin,
  NavigationMixin,
) {
  @Prop({ type: [String, Number], required: true }) id!: string | number;

  @global.Mutation addNotification!: Function;

  @nationalEvents.Getter('item') nationalEvent!: NationalEvent;
  @nationalEvents.Getter('loading') nationalEventLoading!: boolean;
  @nationalEvents.Getter('loaded') nationalEventLoaded!: boolean;

  @nationalEventParticipations.Action createItem!: (params: RestParams) => Promise<void>;
  @nationalEventParticipations.Action destroy!: (params: { id: string }) => Promise<void>;
  @nationalEventParticipations.Action loadEmpty!: () => Promise<void>;
  @nationalEventParticipations.Action updateItem!: (params: RestParams) => Promise<void>;
  @nationalEventParticipations.Getter initialItem!: string;
  @nationalEventParticipations.Getter item!: NationalEventParticipation;
  @nationalEventParticipations.Getter error!: ErrorResponse;
  @nationalEventParticipations.Getter data!: Array<NationalEventParticipation>;
  @nationalEventParticipations.Getter('loaded') nationalEventParticipationsLoaded!: boolean;
  @nationalEventParticipations.Getter loading!: boolean;
  @nationalEventParticipations.Mutation('item') setItem!: (item: NationalEventParticipation | null) => void;
  @nationalEventParticipations.Mutation('initialItem') setInitialItem!: (item: string) => void;
  @nationalEventParticipations.Mutation('lastLoadedAt') setLastLoadedAt!: (val: Date | null) => void;
  @nationalEventParticipations.Mutation('data') setData!: (data: NationalEventParticipation[]) => void;

  // eslint-disable-next-line class-methods-use-this
  get contactQueryDefinition(): any {
    return {
      key: 'data',
      slug: 'contacts',
      text: 'searchable_text',
      value: 'id',
      params: {
        fields: 'searchable_text,full_name,gender,v1_contact_id,email,user.id,status',
        per_page: 10,
        order: 'full_name',
      },
    };
  }

  get delegatesCount(): number {
    return this.data.reduce<number>((acc, p) => {
      if (p.type === 'delegate') {
        return acc + 1;
      }

      return acc;
    }, 0);
  }

  // eslint-disable-next-line class-methods-use-this
  get delegationDocuments(): Document[] {
    if (!this.nationalEvent) {
      return [];
    }

    switch (this.nationalEvent.type) {
      case 'congres':
        return [];
      case 'conseil-national':
        return [{
          href: 'https://api-wp.quebecsolidaire.net/wp-content/uploads/2024/03/20240419-troussedeleguee.pdf',
          title: 'Trousse de la personne déléguée',
          subtitle: 'Informations pratiques pour vivre un bon CN',
        }];
      case 'autre':
      default:
        return [];
    }
  }

  dialog: DialogProps = {
    callback: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    loading: false,
    message: null,
    title: null,
    value: false,
  };

  get instanceName(): string | undefined {
    return this.currentInstance?.name ?? '';
  }

  get instanceDelegationSize(): number | null {
    const delegationSize = this.currentInstance?.meta?.delegation_size;
    if (delegationSize === undefined || delegationSize === null || delegationSize === '') {
      return null;
    }

    return Number.parseInt(this.currentInstance?.meta?.delegation_size, 10) ?? 0;
  }

  // eslint-disable-next-line class-methods-use-this
  get instanceDocuments(): Document[] {
    return [];
  }

  get hasChanged(): boolean {
    return this.initialItem !== JSON.stringify(this.item);
  }

  get hasGenders(): boolean | null {
    if (!this.data || this.data.length === 0) {
      return null;
    }

    return this.data.reduce<boolean>((acc, p) => (p.type === 'observer'
      || (!!p.contact && p.contact.gender !== null)) && acc, true);
  }

  get hasEmails(): boolean | null {
    if (!this.data || this.data.length === 0) {
      return null;
    }

    return this.data.reduce<boolean>((acc, p) => (!!p.contact && !!p.contact.email) && acc, true);
  }

  get hasMemberships(): boolean | null {
    if (!this.data || this.data.length === 0) {
      return null;
    }

    return this.data.reduce<boolean>((acc, p) => (!!p.contact && p.contact.status === 'MER') && acc, true);
  }

  get hasParity(): boolean | null {
    if (!this.data || this.data.length === 0) {
      return null;
    }

    if (this.instanceDelegationSize === 1 && this.parityValue[0] === 1) {
      return false;
    }

    return this.parityValue[1] >= this.parityValue[0];
  }

  get loaded(): boolean {
    return this.nationalEventLoaded && this.nationalEventParticipationsLoaded;
  }

  participationStates: Array<SelectItem<NationalEventParticipationState>> = [
    { text: 'Inscrit·e', value: 'registered' },
    { text: 'Confirmé·e', value: 'designated' },
    { text: 'Rejeté·e', value: 'rejected' },
  ];

  get participationStateLabels(): Record<string, string> {
    return this.participationStates.reduce<Record<string, string>>((acc, t) => {
      acc[t.value] = t.text;
      return acc;
    }, {});
  }

  participationTypes: Array<SelectItem<NationalEventParticipationType>> = [
    { text: 'Délégué·e', value: 'delegate' },
    { text: 'Observateur·trice', value: 'observer' },
  ];

  get participationTypeLabels(): Record<string, string> {
    return this.participationTypes.reduce<Record<string, string>>((acc, t) => {
      acc[t.value] = t.text;
      return acc;
    }, {});
  }

  get parityMessage(): string {
    if (this.data.length === 1 && this.parityValue[0] === 1) {
      return "Attention: il faut demander une dérogation du secrétariat général pour une délégation composée d'un homme seul.";
    }

    if (this.parityValue[1] < this.parityValue[0]) {
      const message = this.$t('national_events.extra_men');

      if (typeof message === 'string') {
        return message;
      }
    }

    return '';
  }

  get parityValue(): [number, number] {
    return this.data.reduce<[number, number]>((acc, p: NationalEventParticipation) => {
      if (p.type === 'observer' || !p.contact) {
        return acc;
      }

      const [male, female] = acc;
      switch (p.contact.gender) {
        case 'male':
          return [male + 1, female];
        case 'female':
          return [male, female + 1];
        default:
          return acc;
      }
    }, [0, 0]);
  }

  get tableHeaders() {
    return [
      {
        text: 'Nouveau?',
        value: 'id',
        sortable: false,
        width: '1%',
      },
      {
        text: 'Contact',
        value: 'contact',
        sortable: false,
      },
      {
        text: 'Type',
        value: 'type',
        sortable: false,
        width: '10%',
      },
      ...(this.userHas('NATIONAL_EVENTS_UPDATE') ? [{
        text: 'Statut',
        value: 'state',
        sortable: false,
        width: '10%',
      }] : []),
      {
        text: '',
        value: 'actions',
        sortable: false,
        width: '1%',
      },
    ];
  }

  get viewReady(): boolean {
    return this.loaded
      && !this.nationalEventLoading
      && !this.loading
      && this.nationalEvent
      && String(this.nationalEvent.id) === this.id;
  }

  async addEmptyItem(): Promise<void> {
    if (this.hasChanged) {
      await new Promise((resolve) => {
        this.dialog.callback = async () => {
          await this.loadEmpty();
          this.dialog.value = false;
          resolve(true);
        };

        this.dialog.color = 'warning';
        this.dialog.icon = 'mdi-plus';
        this.dialog.loading = false;
        this.dialog.message = 'Voulez-vous vaiment continuer? Les données non sauvegardées seront perdues.';
        this.dialog.title = 'Ajout de délégué·es ou observateur·trices';
        this.dialog.value = true;
      });
      return;
    }

    await this.loadEmpty();
  }

  async clearEmptyItem(): Promise<void> {
    if (this.hasChanged) {
      await new Promise((resolve) => {
        this.dialog.callback = async () => {
          this.setItem(null);
          this.setInitialItem('null');
          this.dialog.value = false;
          resolve(true);
        };

        this.dialog.color = 'warning';
        this.dialog.icon = 'mdi-plus';
        this.dialog.loading = false;
        this.dialog.message = 'Voulez-vous vaiment continuer? Les données non sauvegardées seront perdues.';
        this.dialog.title = 'Ajout de délégué·es ou observateur·trices';
        this.dialog.value = true;
      });
      return;
    }

    this.setItem(null);
    this.setInitialItem('null');
  }

  async deleteParticipation(item: PersistedNationalEventParticipation) {
    await this.destroy({ id: `${item.id}` });
    await this.reloadDataRoutesData(['national_event_participations.index'], true);
  }

  goToContactForm(event: MouseEvent, contactId: number): void {
    this.setLastLoadedAt(null);
    this.goTo(event, `/contacts/${contactId}`);
  }

  async saveItemAndReloadData() {
    this.updateItemKey('national_event_id', this.nationalEvent.id);
    this.updateItemKey('instance_id', this.currentInstanceId);

    try {
      if (!this.item.id) {
        await this.createItem({});
      } else {
        await this.updateItem({});
      }
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: this.remainingErrorMessages([]).join(', ')
          || "Une erreur s'est produite lors de la sauvegarde de la participation.",
        timeout: -1,
      });
      return;
    }

    this.setItem(null);
    this.setInitialItem('null');
    await this.reloadDataRoutesData(['national_event_participations.index'], true);
  }

  setGlobalTitle() {
    if (!this.routeDataLoaded) {
      this.$store.commit('global/title', 'Inscription de délégation');
      this.$store.commit('global/subtitle', 'Chargement...');
    } else {
      this.$store.commit('global/title', this.nationalEvent?.name);
      this.$store.commit('global/subtitle', 'Inscription de délégation');
    }
    this.$emit('updateHead');
  }

  startEditItem(item: NationalEventParticipation) {
    this.setItem(item);
    this.setInitialItem(JSON.stringify(item));
  }

  updateItemKey(name: string, value: any) {
    const item = {
      ...this.item,
    };
    item[name] = value;
    if (name === 'contact') {
      item.contact_id = value?.id ?? null;
    }
    this.setItem(item);
  }


  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged() {
    this.setGlobalTitle();
  }

  @Watch('$route', { deep: true, immediate: true })
  setAction() {
    this.setGlobalTitle();
  }

  @Watch('currentInstance')
  onNationalEventDelegationInstanceIdChanged(newValue?: Partial<Instance>) {
    if (!newValue) {
      return;
    }

    if (newValue.rights_slugs === undefined) {
      return;
    }

    if (!newValue.rights_slugs.includes('NATIONAL_EVENTS_RETRIEVE')) {
      this.$router.push('/');
      return;
    }

    this.setData([]);
    this.reloadDataRoutesData(['national_event_participations.index'], true);
  }
}
