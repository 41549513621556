var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "national-event-delegation-participant-select-item d-flex" },
    [
      _c("div", [
        _c("div", { staticClass: "mt-2 mb-1" }, [
          _vm._v(" " + _vm._s(_vm.value.full_name) + " ")
        ]),
        _c(
          "div",
          {
            class: {
              email: true,
              "red--text": !_vm.value.email
            }
          },
          [
            _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
              _vm._v("mdi-at")
            ]),
            _c("small", [_vm._v(_vm._s(_vm.value.email || "Pas de courriel"))])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mb-2" },
          [
            _c("qs-boolean-indicator", {
              staticClass: "mr-1",
              attrs: { value: !!_vm.value.user, small: "" }
            }),
            _c("small", [
              _vm._v(
                _vm._s(
                  !_vm.value.user
                    ? "Ne s'est jamais connecté"
                    : "A un compte utilisateur"
                )
              )
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "text-right flex-grow-1" }, [
        _c(
          "div",
          {
            class:
              "mt-2 mb-1 " + (_vm.value.status !== "MER" ? "red--text" : "")
          },
          [
            _vm._v(
              " " + _vm._s(_vm._f("status")(_vm.value.status, "contacts")) + " "
            )
          ]
        ),
        _c(
          "div",
          { class: !_vm.value.gender && _vm.shouldBeMember ? "red--text" : "" },
          [_vm._v(_vm._s(_vm._f("gender")(_vm.value.gender)))]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }