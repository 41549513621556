var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "home", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("h2", { staticClass: "subtitle" }, [
              _vm._v("👋 Bienvenue sur La Base")
            ])
          ]),
          !_vm.loading && !_vm.userIsConnected
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "4" } },
                [
                  _c(
                    "v-alert",
                    {
                      attrs: {
                        border: "left",
                        color: "qs-beige",
                        icon: "mdi-account-key",
                        tile: ""
                      }
                    },
                    [
                      _c("p", { staticClass: "body-1" }, [
                        _vm._v(
                          "Vous devez être connecté pour utiliser la plateforme."
                        )
                      ]),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "info", outlined: "", tile: "" },
                          on: {
                            click: function($event) {
                              return _vm.$store.commit(
                                "global/loginDialog",
                                true
                              )
                            }
                          }
                        },
                        [_vm._v(" Connexion ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.userIsConnected && _vm.user.accepted_confidentiality_agreement
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _vm.user.instance_users_expiring_soon &&
                  _vm.user.instance_users_expiring_soon.length > 0
                    ? _c(
                        "v-alert",
                        {
                          staticClass: "mb-4 body-2",
                          attrs: { color: "warning", icon: "mdi-key", tile: "" }
                        },
                        [
                          _c("p", { staticClass: "font-weight-bold" }, [
                            _vm._v(
                              "Vous avez des permissions qui expirent bientôt sur:"
                            )
                          ]),
                          _c(
                            "ul",
                            _vm._l(
                              _vm.user.instance_users_expiring_soon,
                              function(instanceName, index) {
                                return _c("li", { key: index }, [
                                  _vm._v(" " + _vm._s(instanceName) + " ")
                                ])
                              }
                            ),
                            0
                          ),
                          _c("p", [
                            _vm._v(
                              " Au besoin, contactez la personne responsable des permissions pour ces instances afin que vos accès soient renouvelés. "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.user.instance_users_expired_recently &&
                  _vm.user.instance_users_expired_recently.length > 0
                    ? _c(
                        "v-alert",
                        {
                          staticClass: "mb-4 body-2",
                          attrs: { color: "warning", icon: "mdi-key", tile: "" }
                        },
                        [
                          _c("p", { staticClass: "font-weight-bold" }, [
                            _vm._v(
                              "Vous avez des permissions ayant expirées récemment sur:"
                            )
                          ]),
                          _c(
                            "ul",
                            _vm._l(
                              _vm.user.instance_users_expired_recently,
                              function(instanceName, index) {
                                return _c("li", { key: index }, [
                                  _vm._v(" " + _vm._s(instanceName) + " ")
                                ])
                              }
                            ),
                            0
                          ),
                          _c("p", [
                            _vm._v(
                              " Au besoin, contactez la personne responsable des permissions pour ces instances afin que vos accès soient restaurés. "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-card-title",
                          {
                            staticClass:
                              "text-h3 font-weight-bold text-uppercase"
                          },
                          [_vm._v(" Appels ")]
                        )
                      ],
                      1
                    ),
                    _vm.routeDataLoaded && _vm.assignedCampaigns.length > 0
                      ? _c(
                          "div",
                          { staticClass: "home__assigned-campaigns" },
                          _vm._l(_vm.assignedCampaigns, function(campaign) {
                            return _c(
                              "div",
                              { key: campaign.id },
                              [
                                _c("qs-join-call-campaign-card", {
                                  staticClass: "mt-3",
                                  attrs: {
                                    assigned: "",
                                    campaign: campaign,
                                    slug: "call_campaigns"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.reloadDataRoutesData([
                                        "globalView.callCampaigns"
                                      ])
                                    },
                                    start: function($event) {
                                      return _vm.startCalls(campaign.id)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm.routeDataLoaded &&
                    _vm.unassignedPublicCampaigns.length > 0
                      ? _c(
                          "div",
                          { staticClass: "home__public-campaigns" },
                          _vm._l(_vm.unassignedPublicCampaigns, function(
                            campaign
                          ) {
                            return _c("qs-join-call-campaign-card", {
                              key: campaign.id,
                              staticClass: "mt-3",
                              attrs: {
                                campaign: campaign,
                                slug: "call_campaigns"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.reloadDataRoutesData([
                                    "globalView.callCampaigns"
                                  ])
                                }
                              }
                            })
                          }),
                          1
                        )
                      : _vm._e(),
                    _vm.routeDataLoaded &&
                    _vm.assignedCampaigns.length < 1 &&
                    _vm.unassignedPublicCampaigns.length < 1
                      ? _c(
                          "div",
                          {
                            staticClass: "d-flex align-center justify-center",
                            staticStyle: { "min-height": "200px" }
                          },
                          [
                            _c(
                              "p",
                              { staticClass: "body-2 px-6 text-center" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: { color: "qs-light-blue", large: "" }
                                  },
                                  [_vm._v("mdi-playlist-remove")]
                                ),
                                _c("br"),
                                _vm._v(
                                  " Vous n'êtes affecté·e à aucune campagne d'appels pour l'instant "
                                )
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e(),
                    !_vm.routeDataLoaded
                      ? _c(
                          "div",
                          {
                            staticClass: "d-flex align-center justify-center",
                            staticStyle: { "min-height": "200px" }
                          },
                          [
                            _c("v-progress-circular", {
                              attrs: { color: "primary", indeterminate: "" }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  _vm.userHas("NATIONAL_EVENTS_RETRIEVE")
                    ? [
                        _c(
                          "v-card",
                          { staticClass: "mt-4 mb-4" },
                          [
                            _c(
                              "v-card-title",
                              {
                                staticClass:
                                  "text-h3 font-weight-bold text-uppercase"
                              },
                              [_vm._v(" Inscription de délégation ")]
                            )
                          ],
                          1
                        ),
                        !_vm.nationalEventsLastLoadedAt ||
                        _vm.nationalEventsLoading ||
                        !_vm.nationalEvents
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-center justify-center",
                                staticStyle: { height: "100px" }
                              },
                              [
                                _c("v-progress-circular", {
                                  attrs: { color: "primary", indeterminate: "" }
                                })
                              ],
                              1
                            )
                          : _vm.nationalEvents.length === 0
                          ? _c("div", [
                              _vm._v(
                                " Aucun événement national accessible pour cette instance. "
                              )
                            ])
                          : _c(
                              "div",
                              _vm._l(_vm.nationalEvents, function(
                                nationalEvent
                              ) {
                                return _c(
                                  "div",
                                  { key: nationalEvent.id },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass: "mb-4",
                                        attrs: { tile: "" }
                                      },
                                      [
                                        _c("v-card-title", [
                                          _vm._v(_vm._s(nationalEvent.name))
                                        ]),
                                        _c("v-card-text", [
                                          _c(
                                            "p",
                                            { staticClass: "text-right" },
                                            [
                                              nationalEvent.registration_end_at
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-bold"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Fin des inscriptions:"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("date")(
                                                      nationalEvent.registration_end_at
                                                    )
                                                  )
                                              ),
                                              _c("br"),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-bold"
                                                },
                                                [
                                                  _vm._v(
                                                    "Taille maximale de la délégation:"
                                                  )
                                                ]
                                              ),
                                              _vm.instanceDelegationSize ===
                                              null
                                                ? _c("span", [
                                                    _vm._v(" Non spécifiée ")
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.instanceDelegationSize
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "d-flex" },
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "qs-button",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    to:
                                                      "/national/" +
                                                      nationalEvent.id +
                                                      "/delegation"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "Inscrire sa délégation"
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                      ]
                    : _vm._e()
                ],
                2
              ),
              _c(
                "v-col",
                { attrs: { md: "8" } },
                [
                  _vm.userHas("INSTANCES_RETRIEVE")
                    ? [
                        _vm.userLoaded &&
                        _vm.user.accepted_confidentiality_agreement
                          ? _c(
                              "div",
                              { staticClass: "mb-4" },
                              [_c("instance-stats")],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-card",
                          {
                            staticClass: "home__mandates",
                            attrs: { loading: _vm.mandatesLoading, tile: "" }
                          },
                          [
                            _c(
                              "v-card-title",
                              {
                                staticClass: "text-uppercase font-weight-bold"
                              },
                              [
                                _vm._v(" Rôles actifs "),
                                _c("v-spacer"),
                                _c(
                                  "qs-button",
                                  {
                                    attrs: {
                                      color: "primary",
                                      depressed: "",
                                      href: _vm.changementDeCoCoLink,
                                      small: "",
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v(" Signaler un changement ")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-skeleton-loader",
                              {
                                attrs: {
                                  loading: _vm.mandatesLoading,
                                  type: "list-item-two-line@12"
                                }
                              },
                              [
                                _c(
                                  "v-list",
                                  _vm._l(_vm.mandates, function(mandate) {
                                    return _c(
                                      "v-list-item",
                                      { key: "mandate__" + mandate.id },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c(
                                              "v-list-item-title",
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold pr-0 mr-n1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          mandate.first_name
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          mandate.last_name
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _vm._l(mandate.roles, function(
                                                  role
                                                ) {
                                                  return _c(
                                                    "span",
                                                    {
                                                      key:
                                                        "mandate__" +
                                                        mandate.id +
                                                        "__role" +
                                                        role.id +
                                                        "__type",
                                                      staticClass:
                                                        "text-lowercase body-2"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " , " +
                                                          _vm._s(
                                                            role
                                                              .instance_role_type
                                                              .name
                                                          ) +
                                                          " "
                                                      ),
                                                      role.responsibilities
                                                        .length > 0
                                                        ? [
                                                            _vm._v(
                                                              " (" +
                                                                _vm._s(
                                                                  role.responsibilities
                                                                    .map(
                                                                      function(
                                                                        r
                                                                      ) {
                                                                        return r.name
                                                                      }
                                                                    )
                                                                    .join(", ")
                                                                ) +
                                                                ") "
                                                            )
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  )
                                                })
                                              ],
                                              2
                                            ),
                                            _c("v-list-item-subtitle", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    mandate.v1_contact_id
                                                  ) +
                                                  " "
                                              ),
                                              mandate.email
                                                ? _c("span", [
                                                    _vm._v(
                                                      "· " +
                                                        _vm._s(mandate.email)
                                                    )
                                                  ])
                                                : _vm._e()
                                            ])
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-action-text",
                                          { staticClass: "text-right" },
                                          [
                                            mandate.status
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$i18n.tc(
                                                            "models.contacts.enums.status." +
                                                              mandate.status.toLowerCase(),
                                                            1
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c("br"),
                                            mandate.district
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      mandate.district.name
                                                    )
                                                  )
                                                ])
                                              : _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-italic"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Circonscription manquante"
                                                    )
                                                  ]
                                                ),
                                            mandate.postal_code
                                              ? [
                                                  _c("br"),
                                                  _vm._v(
                                                    _vm._s(
                                                      mandate.postal_code
                                                    ) + " "
                                                  )
                                                ]
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }