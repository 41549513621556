var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { staticClass: "contributions-dashboard pb-12" }, [
    _c("div", { staticClass: "dashboard" }, [
      _c(
        "div",
        { staticClass: "suggestions" },
        [
          _c("h3", [_vm._v("Segments à solliciter")]),
          _c(
            "v-list",
            _vm._l(_vm.segments, function(segment) {
              return _c(
                "v-list-item",
                { key: segment.name, attrs: { "three-line": "" } },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(" " + _vm._s(segment.name) + " ")
                      ]),
                      _c("v-list-item-subtitle", [
                        _vm._v(" " + _vm._s(segment.description) + " ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: { icon: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.applyFilter(
                                                segment,
                                                "contacts"
                                              )
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "info" } },
                                          [_vm._v("mdi-eye")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [_vm._v(" Voir les contacts dans le segment ")]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "ml-2",
                                          attrs: { icon: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.applyFilter(
                                                segment,
                                                "calls"
                                              )
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "info" } },
                                          [_vm._v("mdi-phone")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _vm._v(
                            " Créer une campagne d'appels à partir des contacts dans le segment "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "stats" }, [
        _c("h3", [_vm._v("Objectifs pour " + _vm._s(_vm.currentInstanceName))]),
        _c("div", { staticClass: "stats__inner" }, [
          _c(
            "div",
            { staticClass: "graph" },
            [
              _vm.instanceMeta &&
              _vm.instanceMeta.meta &&
              _vm.instanceMeta.meta.mid_contribution_target
                ? _c("v-progress-circular", {
                    attrs: {
                      color: "primary",
                      size: "192",
                      value:
                        (_vm.contributionsSum /
                          _vm.instanceMeta.meta.contribution_target) *
                        100,
                      width: "12"
                    }
                  })
                : _vm._e(),
              _vm.instanceMeta
                ? [
                    _vm.instanceMeta.meta.mid_contribution_target
                      ? _c("p", [
                          _vm.instanceStatsLoaded
                            ? _c("span", { staticClass: "stat-amount" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("currency")(_vm.contributionsSum)
                                  )
                                )
                              ])
                            : _c("span", [
                                _vm._v("Chargement des contributions")
                              ]),
                          _c("br"),
                          _vm.instanceStatsLoaded
                            ? _c("span", [_vm._v("amassés")])
                            : _vm._e(),
                          _vm._v(" sur l'objectif intermédiaire de"),
                          _c("br"),
                          _c("span", { staticClass: "stat-amount" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.instanceMeta.meta.contribution_target
                                )
                              )
                            )
                          ]),
                          _c("br"),
                          _c("span", [_vm._v("(d'ici au 31 décembre)")])
                        ])
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "message" },
            [
              _c(
                "v-alert",
                {
                  staticClass: "body-2 mb-0",
                  attrs: { color: "warning", icon: "mdi-lightbulb-outline" }
                },
                [
                  _vm._v(
                    " Passez en revue les noms dans les segments à solliciter à gauche et contactez directement celles et ceux que vous connaissez. Rien de tel qu'un message personnalisé pour rappeler à vos connaissances de faire une contribution. ☺️ "
                  )
                ]
              ),
              _vm.instanceMeta &&
              _vm.instanceMeta.meta &&
              _vm.instanceMeta.meta.contribution_message
                ? _c("p", { staticClass: "body-1" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.instanceMeta.meta.contribution_message) +
                        " "
                    )
                  ])
                : _vm._e(),
              _c("p", [
                _vm._v(
                  " Pour vous aider dans la planification des activités de votre association cet hiver et ce printemps, on vous propose un objectif de financement de mi-année. "
                )
              ]),
              _vm.instanceMeta &&
              _vm.instanceMeta.meta &&
              _vm.instanceMeta.meta.paper_form_url
                ? _c("div", [
                    _c("h4", [
                      _vm._v(
                        "Fiche de contribution de " +
                          _vm._s(_vm.currentInstanceName)
                      )
                    ]),
                    _c(
                      "a",
                      {
                        attrs: {
                          id: "paper_form_url",
                          href: _vm.instanceMeta.meta.paper_form_url,
                          target: "_blank"
                        }
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("v-icon", { attrs: { color: "qs-red" } }, [
                              _vm._v("mdi-file-pdf-box")
                            ]),
                            _vm._v(
                              " QS-FC12-02-24--" +
                                _vm._s(_vm.currentInstanceName) +
                                "-" +
                                _vm._s(
                                  String(
                                    _vm.instanceMeta.meta.external_id
                                  ).substring(2, 5)
                                ) +
                                " "
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("v-icon", { attrs: { color: "#333333" } }, [
                              _vm._v("mdi-download-box")
                            ])
                          ],
                          1
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "documentation" }, [
      _c("h3", [_vm._v("Liens utiles")]),
      _c(
        "div",
        { staticClass: "documentation__inner" },
        _vm._l(_vm.documentation, function(link) {
          return _c(
            "v-card",
            {
              key: link.name,
              class: link.class,
              attrs: { href: link.url, target: "_blank" }
            },
            [
              link.image
                ? _c("v-img", { attrs: { src: "/img/" + link.image } })
                : _vm._e(),
              _c("v-card-title", [_vm._v(" " + _vm._s(link.name) + " ")]),
              _c("v-card-text", [_vm._v(" " + _vm._s(link.description) + " ")])
            ],
            1
          )
        }),
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }