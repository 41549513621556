import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';

import { DataCollections } from 'qs_vuetify/src/mixins/DataRouteGuards';

import { MenuItem } from 'qs_vuetify/src/types/components';
import { Model } from 'qs_vuetify/src/types/models';

import store from '../store';

import callCampaigns from './callCampaigns';
import contacts from './contacts';
import electionResults from './electionResults';
import events from './events';
import mailCampaigns from './mailCampaigns';
import nationalEvents from './nationalEvents';
import tags from './tags';
import volunteers from './volunteers';

declare module 'vue-router/types/router' {
  interface Route {
    meta?: any & {
      data?: DataCollections<Model>;
    } & MenuItem;
  }

  interface _RouteConfigBase { // eslint-disable-line @typescript-eslint/class-name-casing
    meta?: any & {
      data?: DataCollections<Model>;
    } & MenuItem;
  }
}

const ContributionsDashboard = () => import('@/views/ContributionsDashboard.vue');
const Home = () => import(/* webpackChunkName: "Home" */'@/views/Home.vue');
const Redirect = () => import(/* webpackChunkName: "Redirct" */'@/views/Redirect.vue');
const InstanceDashboard = () => import('@/views/InstancesDashboard.vue');

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      auth: true,
      data: {
        globalView: {
          callCampaigns: {
            conditional(vm: Vue) {
              const { loaded, user } = vm.$store.state.auth;
              return loaded && user?.accepted_confidentiality_agreement;
            },
          },
        },
        national_events: {
          index: {
            conditional(vm: Vue) {
              return !vm.$store.state.national_events.lastLoadedAt;
            },
            params: {
              fields: 'name,type,description,participations_count,registration_end_at',
              per_page: '-1',
              status: 'published',
            },
          },
        },
      },
      help: process.env.VUE_APP_LACENTRALE_RUBRIQUES_URL,
      menus: ['primary'],
      title: 'Accueil',
    },
  },
  {
    path: '/conseil-national-2024',
    name: 'InstancesDashboard',
    component: InstanceDashboard,
    meta: {
      auth: true,
      data: {
        instances: {
          retrieve: {
            contextual(vm: Vue) {
              return {
                id: vm.$store.getters['auth/instanceId'],
              };
            },
            params: {
              fields: 'name,meta',
            },
          },
        },
      },
      menus: [],
      title: 'Conseil national 2024',
    },
  },
  {
    path: '/contributions',
    name: 'ContributionsDashboard',
    component: ContributionsDashboard,
    meta: {
      auth: true,
      data: {
        globalView: {
          instanceStats: {
            contextual(vm: Vue) {
              return {
                accessible_from: vm.$store.getters['auth/instanceId'],
                instanceId: vm.$store.getters['auth/instanceId'],
                year: new Date().getFullYear(),
              };
            },
            params: {
              fields: [
                'year',
                'monthly_donations',
                '__cache__monthly_donations_generated_at',
              ].join(','),
            },
          },
        },
        instances: {
          retrieve: {
            contextual(vm: Vue) {
              return {
                id: vm.$store.getters['auth/instanceId'],
              };
            },
            params: {
              fields: 'meta',
            },
          },
        },
      },
      menus: [],
      title: 'Tableau de bord des contributions',
    },
  },
  ...contacts,
  ...callCampaigns,
  ...mailCampaigns,
  ...events,
  ...tags,
  ...volunteers,
  ...electionResults,
  ...nationalEvents,
  {
    path: '/goto/:model/:field/:value',
    props: true,
    component: Redirect,
    meta: {
      auth: true,
      data: {},
    },
  },
  {
    path: '/*',
    redirect: () => ({ name: 'Home' }),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit('global/reset');

  if (store.state.auth) {
    const { instanceId, user } = store.state.auth;
    if (instanceId) {
      store.commit('call_campaigns/prefix', `/instances/${instanceId}`);
      store.commit('campaigns/prefix', `/instances/${instanceId}`);
      store.commit('events/prefix', `/instances/${instanceId}`);
      store.commit('mail_campaigns/prefix', `/instances/${instanceId}`);
      store.commit('contacts/prefix', `/instances/${instanceId}`);
    }

    if (user && !user.accepted_confidentiality_agreement && to.name !== 'Home') {
      next({
        path: '/',
        query: {
          r: to.fullPath,
        },
      });
      store.commit('global/confidentialityAgreementDialog', true);
      return;
    }
  }

  next();
});

export default router;
