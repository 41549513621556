<template>
  <div class="national-event-delegation-participant-select-item d-flex">
    <div>
      <div class="mt-2 mb-1">
        {{ value.full_name }}
      </div>
      <div :class="{
        email: true,
        'red--text': !value.email,
      }">
        <v-icon small class="mr-1">mdi-at</v-icon>
        <small>{{ value.email || 'Pas de courriel' }}</small>
      </div>
      <div class="mb-2">
        <qs-boolean-indicator :value="!!value.user" small class="mr-1" />
        <small>{{ !value.user ? "Ne s'est jamais connecté" : "A un compte utilisateur" }}</small>
      </div>
    </div>
    <div class="text-right flex-grow-1">
      <div
        :class="`mt-2 mb-1 ${(value.status !== 'MER') ? 'red--text' : ''}`"
      >
        {{ value.status | status('contacts') }}
      </div>
      <div :class="!value.gender && shouldBeMember ? 'red--text' : ''">{{ value.gender | gender }}</div>
    </div>
  </div>
</template>

<script>
import QsBooleanIndicator from 'qs_vuetify/src/components/Indicators/QsBooleanIndicator.vue';

export default {
  name: 'NationalEventDelegationParticipantSelectItem',
  components: {
    QsBooleanIndicator,
  },
  props: {
    shouldBeMember: {
      required: false,
      default: false,
      type: Boolean,
    },
    value: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style lang="scss">
.national-event-delegation-participant-select-item {
  width: 100%;
}
</style>
