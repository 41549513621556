var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      class: [
        "qs-base-item-card",
        "volunteer-card",
        { "volunteer-card--archived": !_vm.item.is_active }
      ],
      attrs: { flat: "", tile: "", href: _vm.href, to: _vm.to },
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [
      _c(
        "v-card-title",
        { staticClass: "d-flex pb-1" },
        [
          _vm.showChecked
            ? _c("v-checkbox", {
                staticClass: "flex-shrink align-self-start mt-1",
                attrs: { "input-value": _vm.selected, "hide-details": "" },
                on: {
                  change: function($event) {
                    return _vm.$emit("check", $event)
                  },
                  click: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                  }
                }
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "flex-grow text-truncate align-self-start mt-1" },
            [
              _vm.item.first_name || _vm.item.last_name
                ? _c("h1", { staticClass: "text-h2 mb-0 font-weight-medium" }, [
                    _vm._v(" " + _vm._s(_vm.item.first_name) + " "),
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v(_vm._s(_vm.item.last_name))
                    ])
                  ])
                : _vm.item.email
                ? _c("h1", { staticClass: "text-h5 mb-0" }, [
                    _vm._v(_vm._s(_vm.item.email))
                  ])
                : _c("h1", { staticClass: "text-h5 mb-0" }, [
                    _vm._v("#" + _vm._s(_vm.item.v1_item))
                  ]),
              _vm.item.first_name || _vm.item.last_name || _vm.item.email
                ? _c("p", { staticClass: "subtitle mb-0 font-weight-medium" }, [
                    _vm._v(" #" + _vm._s(_vm.item.v1_contact_id) + " ")
                  ])
                : _vm._e(),
              _c("p", { staticClass: "body-2 mb-0 pb-0" }, [
                _vm._v(_vm._s(_vm.item.district ? _vm.item.district.name : "-"))
              ]),
              _vm._l(_vm.item.adopted_instances, function(instance) {
                return _c(
                  "p",
                  {
                    key: instance.id,
                    staticClass: "body-2 font-italic mt-0 pt-0"
                  },
                  [_vm._v(" Adopté·e par " + _vm._s(instance.name) + " ")]
                )
              })
            ],
            2
          ),
          _c("v-spacer"),
          _c("div", { staticClass: "flex-shrink align-self-start mt-1" }, [
            _c(
              "div",
              { staticClass: "d-flex justify-end" },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("status")(_vm.item.status, "contacts")) +
                    " "
                ),
                _c("qs-contact-favorite-button", {
                  staticClass: "ml-6",
                  attrs: { id: _vm.item.id }
                }),
                _c("qs-contact-volunteer-button", {
                  staticClass: "ml-6",
                  attrs: { id: _vm.item.id }
                })
              ],
              1
            ),
            _c("div", { staticClass: "body-2 text-right mt-2" }, [
              _vm.item.home_phone
                ? _c(
                    "div",
                    [
                      _c("qs-copy-to-clipboard", {
                        attrs: {
                          size: "x-small",
                          text: _vm.$filters.phone(_vm.item.home_phone)
                        }
                      }),
                      _vm._v(
                        " " + _vm._s(_vm._f("phone")(_vm.item.home_phone)) + " "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.item.email
                ? _c(
                    "div",
                    [
                      _c("qs-copy-to-clipboard", {
                        attrs: { size: "x-small", text: _vm.item.email }
                      }),
                      _vm._v(" " + _vm._s(_vm.item.email) + " ")
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        ],
        1
      ),
      _vm.showText && _vm.futureEvents
        ? _c(
            "v-card-text",
            { staticClass: "mt-2" },
            _vm._l(_vm.futureEvents, function(event) {
              return _c("contact-event-chip", {
                key: event.id,
                staticClass: "mr-2",
                attrs: { item: event }
              })
            }),
            1
          )
        : _vm._e(),
      _vm.showAlerts
        ? _c(
            "div",
            { staticClass: "qs-base-item-card__alerts" },
            [
              !_vm.item.email || !_vm.item.home_phone
                ? _c(
                    "v-alert",
                    { attrs: { outlined: "", dense: "", type: "warning" } },
                    [
                      _vm._v(" Coordonnées manquantes : "),
                      !_vm.item.email
                        ? _c("strong", [_vm._v(" courriel")])
                        : _vm._e(),
                      !_vm.item.email && !_vm.item.home_phone
                        ? _c("span", [_vm._v(",")])
                        : _vm._e(),
                      !_vm.item.home_phone
                        ? _c("strong", [_vm._v(" téléphone")])
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              Array.isArray(_vm.item.future_events) &&
              _vm.futureEvents.length === 0
                ? _c(
                    "v-alert",
                    { attrs: { outlined: "", dense: "", type: "warning" } },
                    [_vm._v(" Aucun événement à venir ")]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }