


















































































































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';

import { Instance } from 'qs_vuetify/src/types/models';

const instances = namespace('instances');

interface Document { href: string; title: string; subtitle: string }

@Component({
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class InstanceDashboard extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
) {
  @instances.Getter item!: Instance;
  @instances.Getter loading!: boolean;

  delegates: Document[] = [
    {
      href: 'https://api-wp.quebecsolidaire.net/wp-content/uploads/2024/03/20240419-troussedeleguee.pdf',
      title: 'Trousse de la personne déléguée',
      subtitle: 'Informations pratiques pour vivre un bon CN',
    },
  ];

  amendments: Document[] = [
    {
      href: 'https://api-wp.quebecsolidaire.net/wp-content/uploads/2024/03/cn-2024-01-d01-ordre-du-jour-v2.pdf',
      title: 'Ordre du jour',
      subtitle: 'Horaire de la fin de semaine',
    },
    {
      href: 'https://api-wp.quebecsolidaire.net/wp-content/uploads/2024/03/20240417-cahierproposition-v2.pdf',
      title: 'Cahier de propositions',
      subtitle: 'Toutes les propositions qui seront débattues pendant la fin de semaine',
    },
  ];

  get instanceName(): string | undefined {
    if (this.item) {
      return this.item.name;
    }

    return '';
  }

  get instanceDelegationSize(): string | undefined {
    if (this.item && this.item.meta) {
      return this.item.meta.delegation_size;
    }

    return '';
  }
}
