export default {
  call_campaigns: {
    add_tag: 'Ajouter une étiquette',
    answered: 'A répondu',
    calls_to_do: 'Aucun appel à faire | {count} appel à faire | {count} appels à faire',
    done_exchanges: '<span class="num">Aucun</span> contacté | <span class="num">{count}</span> contacté | <span class="num">{count}</span> contactés',
    failed_exchanges: '<span class="num">Aucun</span> échoué | <span class="num">{count}</span> échoué | <span class="num">{count}</span> échoués',
    left_message: 'Laissé un message',
    notify_deceased: 'Signaler un décès',
    reserved_exchanges: '<span class="num">Aucun</span> réservé | <span class="num">{count}</span> réservé | <span class="num">{count}</span> réservés',
    script: 'Scénario d\'appel',
    sub_email: 'abboner aux communications courriels',
    sub_phone: 'abboner aux communications téléphoniques',
    unsub_email: 'désabboner aux communications courriels',
    unsub_phone: 'désabboner aux communications téléphoniques',
    users_affected: 'Aucun·e appelant·e affecté·e | {count} appelant·e affecté·e | {count} appelant·es affecté·es',
  },
  mail_campaigns: {
    clicked: 'Aucun clic | {count} cliqué | {count} cliqués',
    contacts: 'Aucun contact | {count} contact | {count} contacts',
    opened: 'Aucun ouvert | {count} ouvert | {count} ouverts',
  },
  pointage_stats: {
    electors_count: 'Électrices et électeurs inscrits',
    telephones_count: 'Nb. avec téléphone',
    telephones_ratio: '% avec téléphone',
    attempts_count: 'Nb. de tentatives',
    marks_count: 'Nb. de pointages',
    marks_electors_ratio: '% des électeurs pointés',
    marks_s_count: 'Nb. de S',
    marks_s_ratio: '% de S',
    electors_s_ratio: '% des électeurs S',
    marks_p_count: 'Nb. de I+',
    marks_p_ratio: '% de I+',
    marks_i_count: 'Nb. de I',
    marks_i_ratio: '% de I',
    marks_a_count: 'Nb. de A',
    marks_a_ratio: '% de A',
    marks_nrp_count: 'Nb. de NRP',
    marks_nrp_ratio: '% de NRP',
    marks_nvp_count: 'Nb. de NVP',
    marks_nvp_ratio: '% de NVP',
    members_count: 'Nb. de MER + MEC',
    members_ratio: '% de MER + MEC',
    contacts_count: 'Nb. de contacts',
    contacts_ratio: '% de contacts',
    unmarked_previous_election_s_count: 'Nb de S élection précédente',
    unmarked_previous_election_s_ratio: '% de S élection précédente',
    unmarked_under_40_count: 'Nb d\'électeurs de moins de 40 ans',
    unmarked_under_40_ratio: '% d\'électeurs de moins de 40 ans',
    core_gotv_left_count: 'Nb de S à faire sortir',
    core_gotv_left_ratio: '% de S à faire sortir',
    peripheral_gotv_left_count: 'Nb de I+ / S élection précédente à faire sortir',
    peripheral_gotv_left_ratio: '% de I+ / S élection précédente à faire sortir',
    total_gotv_left_count: 'Nb de marques totales à faire sortir',
    total_gotv_left_ratio: '% de marques totales à faire sortir',
  },
  national_events: {
    can_delegate: '{name} ne peut déléguer personne. | {name} peut déléguer | {name} peut déléguer',
    delegates_count: 'aucun·e délégué·e | {count} délégué·e | {count} délégué·es',
    delegation_size_not_specified: "La taille de votre délégation n'a pas été configurée.",
    extra_men: "La délégation compte trop d'hommes.",
    people: 'personne | {count} personne | {count} personnes',
  },
};
