


































































































































































































































































































import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsJoinCallCampaignCard from 'qs_vuetify/src/components/CallCampaign/QsJoinCallCampaignCard.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';

import { PersistedNationalEvent, PersistedCallCampaign, PersistedUser } from 'qs_vuetify/src/types/models';

import InstanceStats from '@/components/InstanceStats.vue';

import MandatesMixin from '@/mixins/MandatesMixin';

const auth: any = namespace('auth');
const globalView: any = namespace('globalView');
const callCampaignsView: any = namespace('callCampaignsView');
const nationalEvents: any = namespace('national_events');

@Component({
  components: {
    InstanceStats,
    QsButton,
    QsJoinCallCampaignCard,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class Home extends mixins(AuthenticationMixin, DataRouteGuards, MandatesMixin) {
  @auth.Getter instanceId!: number;
  @auth.Getter instanceLoading!: boolean;
  @auth.Getter loaded!: boolean;
  @auth.Getter userLoading!: boolean;
  @auth.Getter loading!: boolean;
  @globalView.Getter callCampaigns!: Array<PersistedCallCampaign>;

  @callCampaignsView.Mutation clearSeenContactIds!: () => void;

  @globalView.Mutation('callCampaigns')
  setCallCampaigns!: (arg: Array<PersistedCallCampaign>) => unknown;

  @globalView.Mutation('callCampaignsLoaded')
  setCallCampaignsLoaded!: (arg: boolean) => unknown;

  @nationalEvents.Getter('data') nationalEvents!: Array<PersistedNationalEvent>;
  @nationalEvents.Getter('lastLoadedAt') nationalEventsLastLoadedAt!: Date;
  @nationalEvents.Getter('loading') nationalEventsLoading!: boolean;

  mounted() {
    // Non connecté: modale de connexion
    if (!this.user) {
      if (this.$route.query && this.$route.query.r) {
        this.$store.commit('global/loginDialog', true);
      }
      return;
    }

    // Connecté
    // Entente de confidentialité non acceptée: modale d'acceptation
    if (!this.user.accepted_confidentiality_agreement) {
      this.$store.commit('global/confidentialityAgreementDialog', true);
    }

    this.onUserIsConnectedChanged(true);
  }

  get assignedCampaigns(): Array<PersistedCallCampaign> {
    return this.callCampaigns.filter(
      (c: PersistedCallCampaign) => c.users && c.users.find(
        (u: PersistedUser) => u.id === this.user?.id,
      ),
    );
  }

  // eslint-disable-next-line class-methods-use-this
  get changementDeCoCoLink() {
    return process.env.VUE_APP_ROLES_CHANGE_URL;
  }

  get instanceDelegationSize(): string | null {
    const delegationSize = this.currentInstance?.meta?.delegation_size;
    if (delegationSize === undefined || delegationSize === null || delegationSize === '') {
      return null;
    }

    return `${delegationSize}`;
  }

  get unassignedPublicCampaigns(): Array<PersistedCallCampaign> {
    return this.callCampaigns.filter(
      (c: PersistedCallCampaign) => !!c.public
        && c.users && !c.users.find((u: PersistedUser) => u.id === this.user?.id),
    );
  }

  get userLoaded(): boolean {
    return this.loaded && !this.userLoading && !this.instanceLoading;
  }

  async startCalls(campaignId: number) {
    await this.clearSeenContactIds();

    this.$store.commit('contact_exchanges/item', null);

    this.$router.push(`/call_campaigns/${campaignId}/contact_exchanges/next`);
  }

  @Watch('instanceId')
  onInstanceIdChanged(newInstanceId: number) {
    if (this.user?.accepted_confidentiality_agreement) {
      this.getActiveMandatesForInstance(newInstanceId);
    }
  }

  @Watch('userIsConnected')
  onUserIsConnectedChanged(loaded: boolean) {
    this.setCallCampaigns([]);
    this.setCallCampaignsLoaded(false);

    if (loaded) {
      if (!this.user?.accepted_confidentiality_agreement) {
        this.$store.commit('global/confidentialityAgreementDialog', true);
        return;
      }

      this.reloadDataRoutesData();
      this.reloadDataRoutesData(['globalView.callCampaigns'], true);

      // Données de mandats cf. MandatesMixin
      this.getActiveMandatesForInstance(this.instanceId);
    }
  }
}
